import styled from "styled-components";

const StyledOrders = styled.div`
  padding-top: 94px;

  @media (max-width: 992px) {
    padding-top: 170px;
  }
  @media (max-width: 767px) {
    padding-top: 126px;
  }
  @media (max-width: 350px) {
    padding-top: 101px;
  }
  @media (max-width: 767px) {
    #navbar li {
      display: inline-block;
      margin-right: 20px;
    }
    #navbar li:last-child {
      margin-right: 0px;
    }
  }
  @media (max-width: 640px) {
    #navbar li {
      display: block;
      margin-right: 0px;
    }
  }
  .navbar-fixed-top {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    border-bottom: none;
    padding-bottom: 13px;
    margin-bottom: 0px;
    z-index: 100;
    background-color: white;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.64);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .navbar-fixed-top {
      padding-bottom: 0px;
    }
  }
  @media (max-width: 767px) {
    .navbar-fixed-top ul {
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 767px) {
    .navbar-fixed-top .navbar-header {
      margin-left: 0px !important;
    }
  }
  .navbar-fixed-top a.navbar-brand {
    margin-left: 0px !important;
    height: auto;
    line-height: inherit;
    display: inline-block;
    float: none;
    padding: 0px;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .navbar-fixed-top a.navbar-brand {
      margin-right: 20px !important;
    }
  }
  .navbar-fixed-top .container {
    background-color: white;
    //  padding-top: 20px;
    padding-top: 15px;
  }
  .navbar-fixed-top .container .navbar-text {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    color: #000000;
    font-size: 26px;
    line-height: 26px;
  }
  @media (max-width: 420px) {
    .navbar-fixed-top .container .navbar-text {
      font-size: 20px;
      line-height: 20px;
    }
  }
  @media (max-width: auto) {
    .navbar-fixed-top .container .navbar-text {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .navbar-fixed-top .container .navbar-text button {
    margin-top: 4px;
    border-radius: 0px;
    border: none;
    background-color: #eb2227;
    color: white;
    text-transform: uppercase;
    width: 100%;
    padding: 3px 12px;
  }
  .navbar-fixed-top .navbar-collapse {
    border-color: transparent;
    max-height: auto;
  }
  .navbar-fixed-top .navbar-collapse .navbar-right {
    width: auto;
    float: right;
  }
  @media (max-width: 992px) {
    .navbar-fixed-top .navbar-collapse .navbar-right {
      float: none;
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
    }
    .navbar-fixed-top .navbar-collapse .navbar-right li:first-child {
      margin-left: 0px;
    }
  }
  .navbar-fixed-top .navbar-collapse .navbar-right li {
    line-height: 65px;
  }
  @media (max-width: 767px) {
    .navbar-fixed-top .navbar-collapse .navbar-right li {
      line-height: inherit;
      margin-left: 0px;
    }
  }
  .navbar-fixed-top .navbar-collapse .navbar-right li a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-fixed-top .navbar-right li {
    margin-left: 20px;
  }
  .navbar-fixed-top .navbar-right li a {
    color: #231f20;
    display: inline-block;
    vertical-align: middle;
    padding: 0px;
  }
  .navbar-fixed-top .navbar-right li a.active,
  .navbar-fixed-top .navbar-right li a:hover {
    color: #eb2227;
  }
  .navbar-fixed-top .navbar-right li a.active {
    cursor: default;
  }
  /* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
  /* Carousel base class */
  .carousel {
    height: auto;
    margin-bottom: 20px;
    background-color: #eb2227;
  }
  .carousel .carousel-indicators {
    bottom: 2px;
  }
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    z-index: 10;
    text-align: left;
    text-shadow: 0px 0px 0px transparent;
    left: 15%;
    right: 15%;
    background-color: #eb2227;
    position: static;
  }
  @media (max-width: 992px) {
    .carousel-caption {
      left: 10%;
      right: 10%;
    }
    .carousel-caption p br {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .carousel-caption {
      left: 5%;
      right: 5%;
    }
  }
  .carousel-caption .row {
    margin: 0px;
    display: table;
    width: 100%;
  }
  @media (max-width: 767px) {
    .carousel-caption .row {
      display: block;
      margin: 0 auto;
    }
  }
  .carousel-caption .row > div {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    .carousel-caption .row > div {
      width: 100%;
      display: block;
    }
    .carousel-caption .row > div br {
      display: none;
    }
    .carousel-caption .row > div .well {
      float: none;
    }
  }
  .carousel-caption .row > div button {
    color: white;
    font-size: 26px;
    width: 100%;
    border: none;
    border-radius: 0px;
  }
  .carousel-caption .row > div button.red {
    background-color: #eb2227;
  }
  .carousel-caption .row > div button.black {
    background-color: #000000;
  }
  .carousel-caption .well {
    background-color: white;
    border: none;
    max-width: 367px;
    float: right;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .carousel-caption .well h1 {
    font-size: 36px;
    line-height: 36px;
    margin: 0px;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    .carousel-caption .well h1 {
      white-space: normal;
    }
  }
  .carousel-caption .well p {
    color: black;
  }
  .carousel-caption .well p:last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    .carousel-caption .well button {
      font-size: 16px !important;
      white-space: normal;
    }
  }
  .carousel-caption h1 {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 50px;
    line-height: 45px;
    font-weight: 700;
    color: white;
  }
  /* Declare heights because of positioning of img element */
  .carousel .item {
    height: auto;
    background-color: #eb2227;
    opacity: 0;
  }
  .carousel .item.active,
  .carousel .item.next {
    opacity: 1;
  }
  .carousel .item.show {
    display: block !important;
    position: relative !important;
  }
  .carousel-inner {
    -webkit-transition: height 1s ease;
    -moz-transition: height 1s ease;
    transition: height 1s ease;
    -moz-transition-timing-function: cubic-bezier(0.19, 0.92, 0.27, 0.99);
    transition-timing-function: cubic-bezier(0.19, 0.92, 0.27, 0.99);
    max-width: 921px;
    margin: 0 auto;
  }
  .carousel-inner .container {
    width: auto;
  }
  @media (max-width: 992px) {
    .carousel-inner {
      max-width: 720px;
    }
  }
  @media (max-width: 767px) {
    .carousel-inner {
      max-width: 100%;
    }
  }
  .carousel-inner .item {
    display: block;
    position: absolute;
    left: calc(100%);
    width: 100%;
    transition: left 1s ease;
  }
  .carousel-inner .item.active {
    left: 0px;
  }
  .carousel-inner > .item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: auto;
    opacity: 0;
  }
  .hersey-guy {
    position: relative;
    text-align: center;
    width: 50%;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .hersey-guy img {
    max-width: 388px;
  }
  @media (max-width: 767px) {
    .hersey-guy {
      display: none !important;
    }
  }
  .list-group-item-heading {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;
    position: relative;
    white-space: nowrap;
  }
  .list-group-item-heading img {
    display: none;
    position: absolute;
    top: 0px;
  }
  .list-group-item-heading img.right {
    right: -80px;
  }
  .list-group-item-heading img.left {
    left: -80px;
  }
  a.list-group-item {
    background-color: #fff;
    border: none;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    position: relative;
    color: #4c4c4e;
    font-size: 15px;
    float: right;
  }
  a.list-group-item:hover,
  a.list-group-item:focus {
    cursor: default;
    background-color: #fff !important;
  }
  #how-it-works .container > div div.col-md-3 {
    text-align: center;
    width: 24%;
  }
  #how-it-works .container > div div.col-md-3 img {
    max-height: 126px;
  }
  @media (max-width: 992px) {
    #how-it-works .container > div div.col-md-3 {
      width: 49%;
      float: left;
      margin-bottom: 20px;
      text-align: center;
    }
    #how-it-works .container > div div.col-md-3:last-child {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 420px) {
    #how-it-works .container > div div.col-md-3 {
      width: 100%;
      float: none;
    }
  }
  #map {
    height: 72vh;
    width: 100%;
    position: absolute !important;
  }
  #map h1 {
    margin-top: 0px;
  }
  #map #bodyContent a {
    color: #eb2227;
  }
  #contact-us.current ~ .grey {
    display: none;
  }
  #contact-us .container {
    position: relative;
    z-index: 99;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    #contact-us .container {
      margin: 0px;
      max-width: 65%;
    }
  }
  @media (max-width: 480px) {
    #contact-us .container {
      max-width: 100%;
    }
    #contact-us .container .form-control#inputFirst,
    #contact-us .container .form-control#inputLast {
      display: block !important;
      width: 100% !important;
    }
  }
  #contact-us .container form {
    padding: 20px;
    margin-top: 20px;
  }
  #contact-us .container .form-control {
    margin-bottom: 5px;
  }
  #contact-us .container .form-control#inputFirst,
  #contact-us .container .form-control#inputLast {
    display: inline-block;
    width: 49%;
  }
  #contact-us .container .form-control.telephone {
    width: 43px !important;
  }
  #contact-us .container button.submit {
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    margin-top: 20px !important;
    border: none;
    border-radius: 0px;
    min-width: 100%;
    clear: both;
    text-transform: uppercase;
  }
  #contact-us .container button.submit.red {
    background-color: #eb2227;
  }
  #contact-us .container button.submit.black {
    background-color: #000000;
  }
  #contact-us .container form {
    background-color: white;
  }
  #contact-us textarea {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  .container > div div.col-md-4 {
    text-align: left;
  }
  @media (max-width: 992px) {
    .container > div div.col-md-4 {
      width: 33.3333%;
      float: left;
    }
    .container > div div.col-md-4 .button-item {
      text-align: right;
    }
    .container > div div.col-md-4 button {
      min-width: 90% !important;
      font-size: 14px !important;
      white-space: normal !important;
    }
  }
  @media (max-width: 640px) {
    .container > div div.col-md-4 {
      width: 49%;
      text-align: center;
    }
    .container > div div.col-md-4:last-child {
      width: 100%;
      clear: both;
    }
    .container > div div.col-md-4:last-child ul {
      margin-bottom: 0px !important;
    }
    .container > div div.col-md-4:last-child button {
      min-width: 200px !important;
    }
  }
  @media (max-width: 500px) {
    .container > div div.col-md-4 {
      width: 100%;
      float: none;
    }
  }
  #how-it-works h1 {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
  }
  #how-it-works h1 span {
    color: #eb2227 !important;
  }

  #book-online {
    margin-top: 0px;
    background-color: #f5f5f6;
  }
  #book-online h1 {
    margin-top: 0px;
  }
  #book-online.current ~ .grey {
    display: none;
  }
  #book-online #form10 {
    max-width: 451px;
    width: 100%;
    margin: 30px auto;
    padding: 20px;
    background-color: white;
  }
  @media (max-width: 350px) {
    #book-online #form10 {
      margin: 10px auto;
    }
  }
  #book-online button.submit {
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    margin-top: 20px !important;
    border: none;
    border-radius: 0px;
    min-width: 100%;
    clear: both;
    text-transform: uppercase;
  }
  #book-online button.submit.red {
    background-color: #eb2227;
  }
  #book-online button.submit.black {
    background-color: #000000;
  }
  #book-online .form-control {
    width: 49%;
    display: inline-block;
    margin-bottom: 5px;
  }
  #book-online .form-control#inputAddress {
    width: 59%;
  }
  #book-online .form-control#inputCity {
    width: 39%;
  }
  #book-online .form-control.telephone {
    width: 49px !important;
  }
  @media (max-width: 480px) {
    #book-online .form-control {
      width: 100% !important;
      display: block;
      float: none;
    }
    #book-online .form-control.telephone {
      width: 49px !important;
      display: inline-block;
    }
  }
  #book-online .input-group {
    vertical-align: top;
    width: 49%;
    display: inline-block;
  }
  #book-online .input-group .input-group-btn {
    display: inline-block;
    float: left;
  }
  @media (max-width: 480px) {
    #book-online .input-group {
      width: 100% !important;
      display: block;
      float: none;
    }
    #book-online .input-group:after {
      clear: both;
      display: block;
      content: " ";
      width: 100%;
      height: 10px;
    }
  }
  #book-online .input-group .form-control {
    display: inline-block;
    width: 59% !important;
    float: right;
    margin-bottom: 0px;
  }
  @media (max-width: 480px) {
    #book-online .input-group .form-control {
      width: auto !important;
      display: inline-block;
      float: right;
    }
    #book-online .input-group .form-control#bin-size {
      width: 75% !important;
    }
  }

  #request-quote {
    margin-top: 0px;
    background-color: #f5f5f6;
  }
  #request-quote h1 {
    margin-top: 0px;
  }
  #request-quote.current ~ .grey {
    display: none;
  }
  #request-quote #form1 {
    max-width: 451px;
    width: 100%;
    margin: 30px auto;
    padding: 20px;
    background-color: white;
  }
  @media (max-width: 350px) {
    #request-quote #form1 {
      margin: 10px auto;
    }
  }
  #request-quote button.submit {
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    margin-top: 20px !important;
    border: none;
    border-radius: 0px;
    min-width: 100%;
    clear: both;
    text-transform: uppercase;
  }
  #request-quote button.submit.red {
    background-color: #eb2227;
  }
  #request-quote button.submit.black {
    background-color: #000000;
  }
  #request-quote .form-control {
    width: 49%;
    display: inline-block;
    margin-bottom: 5px;
  }
  #request-quote .form-control#inputAddress {
    width: 59%;
  }
  #request-quote .form-control#inputCity {
    width: 39%;
  }
  #request-quote .form-control.telephone {
    width: 49px !important;
  }
  @media (max-width: 480px) {
    #request-quote .form-control {
      width: 100% !important;
      display: block;
      float: none;
    }
    #request-quote .form-control.telephone {
      width: 49px !important;
      display: inline-block;
    }
  }
  #request-quote .input-group {
    vertical-align: top;
    width: 49%;
    display: inline-block;
  }
  #request-quote .input-group .input-group-btn {
    display: inline-block;
    float: left;
  }
  @media (max-width: 480px) {
    #request-quote .input-group {
      width: 100% !important;
      display: block;
      float: none;
    }
    #request-quote .input-group:after {
      clear: both;
      display: block;
      content: " ";
      width: 100%;
      height: 10px;
    }
  }
  #request-quote .input-group .form-control {
    display: inline-block;
    width: 59% !important;
    float: right;
    margin-bottom: 0px;
  }
  @media (max-width: 480px) {
    #request-quote .input-group .form-control {
      width: auto !important;
      display: inline-block;
      float: right;
    }
    #request-quote .input-group .form-control#bin-size {
      width: 75% !important;
    }
  }

  .phone-wrapper {
    width: 49%;
    display: inline-block;
  }
  .phone-wrapper input {
    text-align: center;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .phone-wrapper label {
    font-weight: normal;
    display: inline-block;
    color: #555 !important;
    font-size: 14px;
    line-height: 1.42857;
  }
  @media (max-width: 480px) {
    .phone-wrapper {
      width: 100%;
      display: block;
    }
  }
  #contact-us {
    margin-top: -20px;
  }
  .row.table {
    display: table;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    .row.table {
      width: 80%;
    }
  }
  .row.table > div {
    padding-left: 0px;
    padding-right: 0px;
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  .row.table > div a {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 992px) {
    .row.table {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .row.table {
      display: block;
      width: 50%;
      margin: 0;
    }
    .row.table > div {
      display: block;
      width: 100%;
      vertical-align: top;
    }
    .row.table > div div {
      margin-bottom: 0px;
    }
    .row.table > div div.col-md-4 {
      text-align: center;
    }
    .row.table > div a {
      float: none;
    }
  }
  @media (max-width: 567px) {
    .row.table {
      width: 100%;
    }
  }
  .tel-link {
    color: #eb2227;
    text-decoration: none;
  }
  .tel-container-heading {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    position: relative;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .grey {
    padding: 20px;
    background-color: #f5f5f6;
  }
  .tel-container {
    text-align: center;
  }
  .tel-container:last-child {
    margin-bottom: 0px;
  }
  .tel-container button {
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    border: none;
    border-radius: 0px;
    min-width: 200px;
  }
  .tel-container button.red {
    background-color: #eb2227;
  }
  .tel-container button.black {
    background-color: #000000;
  }
  @media (max-width: 350px) {
    .navbar-fixed-top a.navbar-brand {
      margin-right: 6px !important;
    }
    .navbar-fixed-top a.navbar-brand img {
      height: 50px !important;
      width: auto !important;
    }
    .navbar-text {
      display: none !important;
    }
  }
  .footer {
    background-color: black;
    text-align: center;
    padding: 20px;
  }
  .footer ul {
    display: inline-block;
    list-style-type: none;
    padding: 0px;
    margin: 0 auto;
  }
  .footer ul li {
    display: inline-block;
    margin-right: 20px;
  }
  .footer ul li:last-child {
    margin-right: 0px;
  }
  @media (max-width: 400px) {
    .footer ul li {
      display: block;
      margin: 0px;
    }
    .footer ul li.social {
      display: inline-block;
      margin-right: 20px;
    }
    .footer ul li.social:last-child {
      margin-right: 0px;
    }
  }
  .footer ul a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 11px;
  }
  .footer ul a.active {
    cursor: default;
    opacity: 0.5;
  }

  [class^="social"],
  [class*=" social"] {
    /* use !important to prevent issues with browser extensions that change fonts */

    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .disclaimer {
    margin-top: 20px;
    font-size: 10px;
    color: white;
  }
  .disclaimer a {
    color: white;
  }
  .disclaimer span {
    margin-left: 20px;
  }
  @media (max-width: 480px) {
    .disclaimer span {
      margin: 0px;
      display: block;
    }
  }
  section {
    position: fixed;
    left: -9999px;
    top: 0px;
  }
  section.current {
    min-height: 72vh;
    position: static;
  }
  section.current#bin-rental ~ .grey {
    display: none;
  }
  section#home {
    //  margin-top: -20px;
    margin-top: -33px;
  }
  section .border-bottom {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #666;
  }
  section .page-header {
    border-color: transparent;
    margin-top: 20px;
  }
  section h1 {
    color: black;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;
  }
  section#bin-rental .col-md-4 > img {
    max-height: 108px;
  }
  @media (max-width: 767px) {
    section#bin-rental .row:last-child .col-md-4:first-child {
      width: 100%;
    }
    section#bin-rental .row:last-child .col-md-4:first-child + div {
      display: none;
    }
  }
  section#bin-rental #list1 {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    section#bin-rental #list1 {
      margin-top: 0px;
    }
  }
  @media (max-width: 767px) {
    section#bin-rental #list1 .list-group {
      margin-bottom: 0px;
    }
  }
  section#bin-rental #list1 h1 {
    margin-left: 20px;
    text-align: left;
  }
  section#bin-rental #list1 li {
    text-align: left;
  }
  section#commercial-dumpsters > h1 {
    width: 90%;
    margin: 20px auto;
  }
  section#commercial-dumpsters.current ~ .grey {
    display: none;
  }
  section#bin-rental .row,
  section#commercial-dumpsters .row,
  section#how-it-works .row {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  section#bin-rental .row:last-child,
  section#commercial-dumpsters .row:last-child,
  section#how-it-works .row:last-child {
    margin-bottom: 40px;
  }
  section#bin-rental .row .list-group,
  section#commercial-dumpsters .row .list-group,
  section#how-it-works .row .list-group {
    margin-bottom: 0px;
  }
  @media (max-width: 992px) {
    section#bin-rental .row .list-group,
    section#commercial-dumpsters .row .list-group,
    section#how-it-works .row .list-group {
      margin-bottom: 20px;
    }
  }
  section#bin-rental .row .list-group.bulleted,
  section#commercial-dumpsters .row .list-group.bulleted,
  section#how-it-works .row .list-group.bulleted {
    margin-left: 10px;
  }
  @media (max-width: 767px) {
    section#bin-rental .row .list-group.bulleted,
    section#commercial-dumpsters .row .list-group.bulleted,
    section#how-it-works .row .list-group.bulleted {
      margin-left: 0px;
    }
  }
  section#bin-rental .row .list-group.bulleted li,
  section#commercial-dumpsters .row .list-group.bulleted li,
  section#how-it-works .row .list-group.bulleted li {
    line-height: 14px;
    padding: 2px;
    position: relative;
  }
  section#bin-rental .row .list-group.bulleted li:before,
  section#commercial-dumpsters .row .list-group.bulleted li:before,
  section#how-it-works .row .list-group.bulleted li:before {
    background-color: #666;
    border-radius: 2px;
    content: " ";
    display: block;
    height: 4px;
    left: -7px;
    position: absolute;
    top: 7px;
    vertical-align: middle;
    width: 4px;
  }
  @media (max-width: 767px) {
    section#bin-rental .row .list-group.bulleted li:before,
    section#commercial-dumpsters .row .list-group.bulleted li:before,
    section#how-it-works .row .list-group.bulleted li:before {
      position: static;
      display: inline-block;
    }
  }
  section#bin-rental .row .list-group li,
  section#commercial-dumpsters .row .list-group li,
  section#how-it-works .row .list-group li {
    border: none;
    border-radius: 0px;
    font-style: italic;
    color: #666;
  }
  section#bin-rental .row .list-group li.button-item,
  section#commercial-dumpsters .row .list-group li.button-item,
  section#how-it-works .row .list-group li.button-item {
    padding: 0px 0px 10px 0px;
  }
  @media (max-width: 767px) {
    section#bin-rental .row .list-group li.button-item,
    section#commercial-dumpsters .row .list-group li.button-item,
    section#how-it-works .row .list-group li.button-item {
      text-align: center;
    }
  }
  section#bin-rental .row .list-group li button,
  section#commercial-dumpsters .row .list-group li button,
  section#how-it-works .row .list-group li button {
    font-style: normal;
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    border: none;
    border-radius: 0px;
    min-width: 200px;
  }
  section#bin-rental .row .list-group li button.red,
  section#commercial-dumpsters .row .list-group li button.red,
  section#how-it-works .row .list-group li button.red {
    background-color: #eb2227;
  }
  section#bin-rental .row .list-group li button.black,
  section#commercial-dumpsters .row .list-group li button.black,
  section#how-it-works .row .list-group li button.black {
    background-color: #000000;
  }
  section#bin-rental .row#list1 li,
  section#commercial-dumpsters .row#list1 li,
  section#how-it-works .row#list1 li {
    padding-left: 40px;
    background: transparent url(../img/checkmark.png) no-repeat scroll 0 8px;
  }
  section#bin-rental .alert,
  section#commercial-dumpsters .alert,
  section#how-it-works .alert {
    margin-top: 20px;
    text-align: center;
  }
  @media (min-width: 992px) {
    section#bin-rental .alert span,
    section#commercial-dumpsters .alert span,
    section#how-it-works .alert span {
      display: block;
    }
  }
  @media (max-width: 767px) {
    section#bin-rental .alert,
    section#commercial-dumpsters .alert,
    section#how-it-works .alert {
      text-align: left;
    }
    section#bin-rental .alert br,
    section#commercial-dumpsters .alert br,
    section#how-it-works .alert br {
      display: none;
    }
  }
  .callout {
    color: black;
    text-align: center;
    font-size: 21px;
    line-height: 24px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  @media (min-width: 992px) {
    .callout span {
      display: block;
    }
  }
  .callout a {
    color: #eb2227;
  }
  @media (max-width: 767px) {
    .callout {
      text-align: left;
    }
    .callout br {
      display: none;
    }
  }
  .truck {
    display: block;
  }
  .preloader-container {
    background-color: #ffffff;
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    z-index: 2147483647;
  }
  .preloader-container img {
    height: 25px !important;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 25px !important;
  }
  .mobile-bg .row {
    min-height: 425px;
    position: relative;
    display: block;
    width: 100%;
    background: transparent url(../img/temp-bg.png) no-repeat scroll 50% 0;
    background-size: contain;
  }
  .mobile-bg .row .mobile-hersey-container {
    display: none;
  }
  @media (max-width: 992px) {
    .mobile-bg .row {
      min-height: 306px;
    }
  }
  @media (max-width: 767px) {
    .mobile-bg .row {
      background: none;
      min-height: inherit;
    }
    .mobile-bg .row .mobile-hersey-container {
      display: block;
    }
    .mobile-bg .row .mobile-hersey-container .col-md-3 {
      padding-left: 0px;
    }
    .mobile-bg .row .mobile-hersey-container .content-1-bg {
      background: transparent url(../img/hat.png) no-repeat scroll 0 15px;
      background-size: 40px auto;
      padding-left: 50px;
    }
    .mobile-bg .row .mobile-hersey-container .content-2-bg {
      background: transparent url(../img/award.png) no-repeat scroll 0 5px;
      background-size: 35px auto;
      padding-left: 50px;
    }
    .mobile-bg .row .mobile-hersey-container .content-3-bg {
      background: transparent url(../img/24.png) no-repeat scroll 0 12px;
      background-size: 35px auto;
      padding-left: 50px;
    }
    .mobile-bg .row .mobile-hersey-container .content-4-bg {
      background: transparent url(../img/recycle.png) no-repeat scroll 0 12px;
      background-size: 35px auto;
      padding-left: 50px;
    }
  }
  .mobile-bg .row .hersey-container {
    display: block;
  }
  @media (max-width: 767px) {
    .mobile-bg .row .hersey-container {
      display: none;
    }
  }
  .mobile-bg .row .hersey-container h1 {
    margin-bottom: 8px;
  }
  .mobile-bg .row .hersey-container p {
    line-height: 19px;
    letter-spacing: 1px;
    color: #4c4c4e;
  }
  .mobile-bg .row .hersey-container p br {
    display: none;
  }
  .mobile-bg .row .hersey-container div {
    position: absolute;
    width: 313px;
  }
  .mobile-bg .row .hersey-container div.content-3,
  .mobile-bg .row .hersey-container div.content-4 {
    width: 290px;
  }
  @media (max-width: 992px) {
    .mobile-bg .row .hersey-container div {
      width: 230px;
    }
    .mobile-bg .row .hersey-container div.content-3,
    .mobile-bg .row .hersey-container div.content-4 {
      width: 220px;
    }
    .mobile-bg .row .hersey-container div h1 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .mobile-bg .row .hersey-container div p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .mobile-bg .row .hersey-container .content-1 {
    top: 72px;
    left: 70px;
  }
  .mobile-bg .row .hersey-container .content-2 {
    top: 235px;
    left: 70px;
  }
  .mobile-bg .row .hersey-container .content-3 {
    top: 72px;
    left: 770px;
  }
  .mobile-bg .row .hersey-container .content-4 {
    top: 235px;
    left: 770px;
  }
  @media (max-width: 1199px) {
    .mobile-bg .row .hersey-container .content-1 {
      top: 64px;
      left: 0px;
    }
    .mobile-bg .row .hersey-container .content-2 {
      top: 218px;
      left: 0px;
    }
    .mobile-bg .row .hersey-container .content-3 {
      top: 64px;
      left: 660px;
    }
    .mobile-bg .row .hersey-container .content-4 {
      top: 218px;
      left: 660px;
    }
  }
  @media (max-width: 992px) {
    .mobile-bg .row .hersey-container .content-1 {
      top: 45px;
      left: 0px;
    }
    .mobile-bg .row .hersey-container .content-2 {
      top: 161px;
      left: 0px;
    }
    .mobile-bg .row .hersey-container .content-3 {
      top: 45px;
      left: 506px;
    }
    .mobile-bg .row .hersey-container .content-4 {
      top: 161px;
      left: 506px;
    }
  }
  #form11 {
    max-width: 100%;
  }
  #map + .container {
    margin-left: 0px;
    width: 50%;
  }
  @media (max-width: 767px) {
    #map {
      position: relative !important;
      height: 50vh !important;
    }
    #form11 {
      max-width: 100% !important;
    }
    #map + .container {
      margin-left: 0px !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  html {
    background: #ffffff url(../img/preload.gif) no-repeat scroll 50% 50%;
  }
  #tempForm {
    position: fixed;
    left: -9999px;
    width: 1px;
    height: 1px;
  }
  #comment {
    position: fixed;
    left: -9999px;
    top: 0px;
    display: block;
    width: 1px;
    height: 1px;
  }
  .error {
    border-color: #eb2227 !important;
  }

  /* MTPUB - additional custom css
-------------------------------------------------- */

  .carousel {
    height: auto;
    min-height: 330px;
    margin-bottom: 20px;
    background-color: #eb2227;
  }

  .navbar-fixed-top .container .navbar-text a {
    margin-top: 4px;
    border-radius: 0px;
    border: none;
    background-color: #eb2227;
    color: white;
    text-transform: uppercase;
    width: 100%;
    padding: 3px 12px;
  }

  .carousel-caption .row > div a {
    color: white;
    font-size: 26px;
    width: 100%;
    border: none;
    border-radius: 0px;
  }
  .carousel-caption .row > div button.red {
    background-color: #eb2227;
  }
  .carousel-caption .row > div button.black {
    background-color: #000000;
  }
  .carousel-caption .row > div a {
    color: white;
    font-size: 26px;
    width: 100%;
    border: none;
    border-radius: 0px;
  }
  .carousel-caption .row > div a.red {
    background-color: #eb2227;
  }
  .carousel-caption .row > div a.black {
    background-color: #000000;
  }

  @media (max-width: 767px) {
    .carousel-caption .well a {
      font-size: 16px !important;
      white-space: normal;
    }
  }

  @media (max-width: 992px) {
    .container > div div.col-md-4 a {
      min-width: 90% !important;
      font-size: 14px !important;
      white-space: normal !important;
    }
  }

  @media (max-width: 640px) {
    .container > div div.col-md-4:last-child a {
      min-width: 200px !important;
    }
  }

  .tel-container a {
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    border: none;
    border-radius: 0px;
    min-width: 200px;
  }
  .tel-container a.red {
    background-color: #eb2227;
  }
  .tel-container a.black {
    background-color: #000000;
  }

  section#bin-rental .row .list-group li a,
  section#commercial-dumpsters .row .list-group li a,
  section#how-it-works .row .list-group li a {
    font-style: normal;
    color: white;
    font-size: 17px;
    width: auto;
    margin: 0 auto;
    border: none;
    border-radius: 0px;
    min-width: 200px;
  }
  section#bin-rental .row .list-group li a.red,
  section#commercial-dumpsters .row .list-group li a.red,
  section#how-it-works .row .list-group li a.red {
    background-color: #eb2227;
  }
  section#bin-rental .row .list-group li a.black,
  section#commercial-dumpsters .row .list-group li a.black,
  section#how-it-works .row .list-group li a.black {
    background-color: #000000;
  }

  .navbar-fixed-top .container .navbar-text a {
    margin-top: 4px;
    border-radius: 0px;
    border: none;
    background-color: #eb2227;
    color: white;
    text-transform: uppercase;
    width: 100%;
    padding: 3px 12px;
  }

  .fright {
    float: right;
  }

  .fleft {
    float: left;
  }

  .spacer {
    margin: 7px 22px 3px 22px;
  }

  .mtop {
    margin-top: 40px;
  }
  .mtop100 {
    margin-top: 100px;
  }
  .mbot {
    margin-bottom: 40px;
  }

  a.black22 {
    font-color: #000;
  }

  .steps-navigation {
    background-color: #eb2227;

    ul {
      margin: 0%;
      padding: 15px 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        width: 60px;
        height: 60px;
        line-height: 60px;
        color: #eb2227;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin: 0 25px;
        background-color: #ba1c28;
        border-radius: 100%;
        cursor: pointer;

        &.active {
          background-color: #ffffff;
        }
      }
    }
  }

  .steps-form {
    text-align: center;
    padding: 35px 0 55px;

    h1 {
      font-size: 42px;
      font-weight: 400;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 380px;
        height: 4px;
        left: 50%;
        bottom: -10px;
        margin-left: -190px;
        background-color: #eb2227;
      }
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin: 25px 0;
      line-height: 26px;
    }

    input,
    select {
      padding: 20px 15px;
      margin: 0 0 20px;
      display: block;
      height: 42px;
      color: #171717

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }

      &::-webkit-input-placeholder {
        color: #171717;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: #171717;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #171717;
        opacity: 1;
      }
      &:-moz-placeholder {
        color: #171717;
        opacity: 1;
      }
    }

    code {
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      display: block;
      margin: 0 0 20px;
      padding: 10px 20px;
      line-height: 26px;
      font-size: 16px;
      color: #4c4c4e;
      font-style: normal;
      background-color: #f5f5f6;
    }

    .btn {
      background-color: #eb2227;
      color: #fff;
      letter-spacing: 2px;
      padding: 16px;

      &.waste-type {
        color: #939597;
        background-color: #d1d2d4;
      }

      &.selected {
        color: #ffffff;
        background-color: #eb2227;
      }
    }
  }

  .carousel.carousel-slider {
    background-color: transparent;

    .control-arrow {
      top: 50%;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: -25px;
      padding: 5px;
      width: 50px;
      height: 50px;
      background-color: #d1d2d4;

      &:hover {
        background-color: #eb2227;

        &:before {
          color: #ba1c28;
        }
      }

      &:before {
        border: 0;
        margin: 0;
        line-height: 23px;
        display: block;
        height: 28px;
        text-align: center;
        width: 43px;
        color: #939597;
      }

      &.control-prev {
        &:before {
          content: "<";
        }
      }

      &.control-next {
        &:before {
          content: ">";
        }
      }
    }

    .slider-wrapper {
      .slider {
        
      }
    }
  }

  .slide {
    border-top: 1px solid #D1D2D4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 80px;

          background-color: transparent;
          &.selected {
            background-color: transparent;
          }

          > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          img {
            width: 20%;
            display: block;
            margin: 0 auto 40px;
          }

          h2 {
            color: #000;
            font-size: 32px;
            font-weight: bold;
            margin: 0;

            span {
              color: #EB2227;
            }
          }

          .slider-description {
            width: 60%;
            padding: 0 10%;
          }

          .slider-summary {
            width: 20%;

            code {
              width: 100%;
            }
          }

          .slider-details {
            color: #4c4c4e;
            padding: 20px 0;

            div {
              text-align: left;
              display: inline-block;
              font-size: 18px;
              line-height: 1.4;
              color: #4C4C4E;
            }

            ul {
              margin: 0;
              padding: 0 0 10px;
              list-style-type: disc;
              list-style-position: inside;
              padding-left: 14px;
            }
          }

          code {
            font-style: normal;
            color: #000000;
            text-align: center;
            font-size: 16px;
            line-height: 1.5;
            padding: 15px 22px;

            div {
              text-align: left;
              display: block;

              p {
                margin: 10px 0;
              }
            }
          }
        }

  .bins-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      padding: 20px;
      background-color: #fff;
      border-radius: 6px;

      h2 {
        margin: 0 0 15px;
      }

      p {
        margin: 0 0 25px;
      }

      button {
        padding: 13px;
        font-size: 12px;
        margin: 0 0 15px;

        &.cancel {
          color: #939597;
          background-color: #d1d2d4;
        }
      }
    }
  }

  ul.order-confirmation {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: flex-startl
      color: #4c4c4e;
      font-weight: bold;
      text-align: left;
      line-height: 2.4;

      &:nth-child(even) {
        background-color: #f5f5f6;
      }

      span {
        width: 200px;
        font-weight: normal;
        margin-left: auto;
      }
    }
  }

  h3 {
    text-align: left;
    font-size: 22px;
    margin: 0 0 20px;
  }

  .order-summary {
    border: 1px solid #757273;

    .order-summary-head {
      padding: 12px 8px;
      background-color: #F5F5F6;

      h2 {
        margin: 0;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .order-summary-content {
      text-align: left;
      padding: 8px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 8px 0;
          text-align: center;
          display: flex;
          align-items: flex-start;

          span {
            margin-left: auto;
          }

          &:last-child {
            margin: 5px 0 0;
            padding: 15px 0;
            font-weight: bold;
            border-top: 1px solid #000;
          }
        }
      }

      button {

      }
    }
  }

  .order-completed {
    text-align: center;

    img {
      width: 120px;
      height: auto;
      display: block;
      margin: 10px auto 15px;
    }

    h2 {
      color: #EB2227;
    }

    h3 {
      text-align: center;
      font-size: 32px;
    }
  }

  .react-datepicker__input-container {
    input {
      margin-bottom: 0;
    }
  }

  .custom-select {
    margin: 0 0 15px;
    text-align: left;
    
    input {
      height: auto;
      margin: 0 0 10px;
    }
  }

  .form-control-invalid {
    border-color: red;
  }

  @media (max-width: 700px) {
    .steps-navigation ul li {
      width: 10%;
      height: 40px;
      line-height: 40px;
      margin: 0 7px;
    }

    .steps-form h1 {
      font-size: 24px;
      width: auto !important;
      margin-left: 0 !important;
      line-height: 50px!important;

      &:after {
        content: "";
        width: 60%;
        bottom: -16px;
        margin-left: -30%;
      }
    }

    .steps-form p {
      font-size: 14px;
    }

    code {
      font-size: 14px !important;
    }

    .steps-form .btn {
      margin: 0 0 20px;
    }

    .carousel.carousel-slider .slider-wrapper .slider .slide > div {
      max-width: 100%;
    }

    .delivery-date {
      display: flex !important;
      justify-content: flex-start !important;
      align-items: baseline !important;
      flex-direction: column !important;
      margin: 0 0 20px !important;

      label {
        display: block;
        margin: 0 0 15px !important;
      }

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
        }
      }
    }

    ul.order-confirmation {
      margin: 20px 0;
    }

    .order-confirmation-toggle {
      margin: 0 0 30px;
    }

    .steps-form .slide {
      padding: 25px 5%;
      flex-direction: column;

      img {
        width: 80%;
      }

      .slider-description {
        width: auto;

        h2 {
          font-size: 26px;
        }
      }

      .slider-summary {
        width: 80%;
      }
    }
  }
`;

export default StyledOrders;
