import { createStore, combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-OK2D8ni2VS3MPz4u1_s7n0B0JRyqMmA",
  authDomain: "herseybins-78c6f.firebaseapp.com",
  databaseURL: "https://herseybins-78c6f.firebaseio.com",
  projectId: "herseybins-78c6f",
  storageBucket: "herseybins-78c6f.appspot.com",
  messagingSenderId: "672975654989",
  appId: "1:672975654989:web:919b61fe47caf7ef"
  // apiKey: "AIzaSyCAivhPuPkWMncH4vY2gt64UvY2lbhF1No",
  // authDomain: "herseybins-dev.firebaseapp.com",
  // databaseURL: "https://herseybins-dev.firebaseio.com",
  // projectId: "herseybins-dev",
  // storageBucket: "herseybins-dev.appspot.com",
  // messagingSenderId: "817366930410",
  // appId: "1:817366930410:web:c9c54d1623a3965c"
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true
};

firebase.initializeApp(firebaseConfig);

firebase.auth();

firebase.firestore();

firebase.storage();

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

const initialState = {};

const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

export default store;

export { rrfProps };
