import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import styled from "styled-components";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  H5,
  P
} from "@bootstrap-styled/v4";

const StyledSignIn = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center
  padding-top: 40px;
  padding-bottom: 40px;

  > div {
    width: 100%;
    max-width: 380px;
    padding: 15px;
    margin: auto;

    h5 {
        text-align: center;
        margin: 0 0 30px;
        text-transform: uppercase;
        font-weight: 300;
    }

    .form-group {
        margin: 0;

        input {
            height: auto;
            padding: 12px;
            font-size: 16px;
        }

        &:first-child {
            input {
                border-radius: .25rem .25rem 0 0;
                border-bottom: 1px solid transparent;

                &:focus {
                    border-bottom: 1px solid hsl(207.79999999999995,98.2%,53.4%);
                }
            }
        }

        &:nth-child(2) {
            input {
                border-radius: 0 0 .25rem .25rem;
            }
        }
    }

    button {
        width: 100%;
        margin: 30px 0 0;
        padding: 0.8rem;
    }

    p {
        height: 24px;
        margin: 30px 0;
        text-align: center;
        font-size: 14px;
        color: rgb(217, 83, 79);
    }
  }
`;

const Auth = ({ firebase, auth, history }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: ""
  });

  useEffect(() => {});

  const login = e => {
    e.preventDefault();
    firebase
      .login({
        email: state.email,
        password: state.password
      })
      .then(() => history.push("/admin/price-classes"))
      .catch(err => setState({ ...state, error: err.message }));
  };

  return (
    <StyledSignIn>
      <div>
        <H5>Hersey Bins</H5>
        <Form onSubmit={e => login(e)}>
          <FormGroup>
            <Row>
              <Col md={12}>
                <Input
                  type="text"
                  placeholder="Email address"
                  value={state.email}
                  onChange={e => setState({ ...state, email: e.target.value })}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={12}>
                <Input
                  type="password"
                  placeholder="Password"
                  value={state.password}
                  onChange={e =>
                    setState({ ...state, password: e.target.value })
                  }
                  autoComplete="password"
                />
              </Col>
            </Row>
          </FormGroup>
          <Button
            color="primary"
            disabled={state.email === "" || state.password === ""}
          >
            SIGN IN
          </Button>
        </Form>
        <P>{state.error}</P>
      </div>
    </StyledSignIn>
  );
};

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    null
  )
)(Auth);
