import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import {
  Container,
  Col,
  Row,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Kbd,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Option,
  InputGroup,
  H5
} from "@bootstrap-styled/v4";
import { Trash2 } from "styled-icons/feather/Trash2";
import { Edit2 } from "styled-icons/feather/Edit2";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Trash = styled(Trash2)`
  color: inherit;
`;

const Edit = styled(Edit2)`
  color: inherit;
`;

const FileUpload = styled.div`
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Bins = props => {
  const [state, setState] = useState({
    modalActive: false,
    modalEdit: false,
    form: {
      image: "",
      type: "",
      size: "",
      property: "",
      description: EditorState.createEmpty()
    }
  });

  useEffect(() => {});

  const submitBin = () => {
    setState({
      modalActive: false,
      modalEdit: false,
      form: {
        image: "",
        type: "",
        size: "",
        property: "",
        description: EditorState.createEmpty()
      }
    });
    const binContent = {
      ...state.form,
      description: draftToHtml(
        convertToRaw(state.form.description.getCurrentContent())
      )
    };
    props.firestore.add("bins", binContent);
  };

  const updateBin = () => {
    setState({
      modalActive: false,
      modalEdit: false,
      form: {
        image: "",
        type: "",
        size: "",
        property: "",
        description: EditorState.createEmpty()
      }
    });
    const binContent = {
      ...state.form,
      description: draftToHtml(
        convertToRaw(state.form.description.getCurrentContent())
      )
    };
    props.firestore.update(`bins/${state.key}`, binContent);
  };

  const editClass = (key, bin) => {
    const contentBlock = htmlToDraft(bin.description);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    setState(previewsState => {
      return {
        ...previewsState,
        modalActive: true,
        modalEdit: true,
        key: key,
        form: {
          ...bin,
          description: editorState
        }
      };
    });
  };

  return (
    <Fragment>
      <Container>
        <Row className="pt-4 pb-4">
          <Col md={6} className="pb-3 d-flex align-items-center">
            <H5 color="gray-dark" className="mb-0">
              All Bins
            </H5>
          </Col>
          <Col md={6} className="pb-3 d-flex justify-content-end">
            <Button
              outline
              color="success"
              style={{ width: "140px" }}
              onClick={e =>
                setState(previewsState => {
                  return { ...previewsState, modalActive: true };
                })
              }
            >
              Add new bin
            </Button>
          </Col>
          <Col md={12}>
            <Table bordered>
              <Thead>
                <Tr>
                  <Th />
                  <Th>Image</Th>
                  <Th>Type</Th>
                  <Th>Property</Th>
                  <Th>Size</Th>
                  <Th>Description</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {!!props.bins &&
                  Object.keys(props.bins).map((key, index) =>
                    props.bins[key] ? (
                      <Tr key={index}>
                        <Th scope="row" className="align-middle text-center">
                          <Kbd>{index + 1}</Kbd>
                        </Th>
                        <Td className="align-middle">
                          {props.bins[key].previewImage ? (
                            <img
                              src={props.bins[key].previewImage}
                              alt=""
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "100%"
                              }}
                            />
                          ) : (
                            "No Image Uploaded"
                          )}
                        </Td>
                        <Td className="align-middle">{props.bins[key].type}</Td>
                        <Td className="align-middle">
                          {props.bins[key].property}
                        </Td>
                        <Td className="align-middle">{props.bins[key].size}</Td>
                        <Td className="align-middle">
                          {props.bins[key].description ? "Yes" : "No"}
                        </Td>
                        <Td className="text-center" style={{ width: "150px" }}>
                          <Button
                            outline
                            color="primary"
                            style={{ margin: "0 4px" }}
                            onClick={e => editClass(key, props.bins[key])}
                          >
                            <Edit size={20} />
                          </Button>
                          <Button
                            outline
                            color="danger"
                            style={{ margin: "0 4px" }}
                            onClick={e =>
                              setState(previewsState => {
                                return {
                                  ...previewsState,
                                  deleteModalActive: true,
                                  keyForDeleteModal: key,
                                  nameForDeleteModal: props.bins[key].name
                                };
                              })
                            }
                          >
                            <Trash size={20} />
                          </Button>
                        </Td>
                      </Tr>
                    ) : null
                  )}
              </Tbody>
            </Table>
          </Col>
          <Col md={12}>
            <Pagination className="d-flex justify-content-center">
              <PaginationItem>
                <PaginationLink previous />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink next />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={state.modalActive}
        size="lg"
        toggle={() =>
          setState(previewsState => {
            return { ...previewsState, modalActive: false };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return { ...previewsState, modalActive: false };
            })
          }
        >
          {state.modalEdit ? "Edit Bin" : "Add New Bin"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Image</Label>
                  <InputGroup>
                    <FileUpload>
                      <label className="custom-file-label">
                        {state.form.previewImage
                          ? "Change Image"
                          : "Choose Image"}
                        {state.form.uploadingImage && (
                          <div className="sk-circle">
                            <div className="sk-circle1 sk-child" />
                            <div className="sk-circle2 sk-child" />
                            <div className="sk-circle3 sk-child" />
                            <div className="sk-circle4 sk-child" />
                            <div className="sk-circle5 sk-child" />
                            <div className="sk-circle6 sk-child" />
                            <div className="sk-circle7 sk-child" />
                            <div className="sk-circle8 sk-child" />
                            <div className="sk-circle9 sk-child" />
                            <div className="sk-circle10 sk-child" />
                            <div className="sk-circle11 sk-child" />
                            <div className="sk-circle12 sk-child" />
                          </div>
                        )}
                      </label>
                      <Input
                        type="file"
                        className="custom-file-input"
                        value={state.form.image}
                        onChange={e => {
                          e.persist();

                          setState(previewsState => {
                            return {
                              ...previewsState,
                              form: {
                                ...state.form,
                                uploadingImage: true
                              }
                            };
                          });

                          props.firebase
                            .uploadFiles(
                              "binsPhotos",
                              e.target.files,
                              "binsPhotos"
                            )
                            .then(res =>
                              setState(previewsState => {
                                return {
                                  ...previewsState,
                                  form: {
                                    ...previewsState.form,
                                    uploadingImage: false,
                                    image: e.target.value,
                                    previewImage: res[0].downloadURL
                                  }
                                };
                              })
                            )
                            .catch(err => console.log(err));
                        }}
                      />
                      {state.form.previewImage && (
                        <Fragment>
                          <label
                            className="custom-file-label"
                            style={{
                              position: "relative",
                              border: 0,
                              padding: 0,
                              height: "auto",
                              margin: "15px 0"
                            }}
                          >
                            Preview Image
                          </label>
                          <img
                            src={state.form.previewImage}
                            alt=""
                            style={{ width: "160px", display: "block" }}
                          />
                        </Fragment>
                      )}
                    </FileUpload>
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    name="Type"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    value={state.form.type}
                    onChange={e =>
                      setState(previewsState => {
                        return {
                          ...previewsState,
                          form: { ...previewsState.form, type: e.target.value }
                        };
                      })
                    }
                  >
                    <Option>Select Bin Type</Option>
                    <Option>Normal</Option>
                    <Option>CC</Option>
                    <Option>CF</Option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Property</Label>
                  <Input
                    type="select"
                    name="Property"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    value={state.form.property}
                    onChange={e =>
                      setState(previewsState => {
                        return {
                          ...previewsState,
                          form: {
                            ...previewsState.form,
                            property: e.target.value
                          }
                        };
                      })
                    }
                  >
                    <Option>Select Property Type</Option>
                    <Option>Residential</Option>
                    <Option>Commercial</Option>
                    <Option>Both</Option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Size</Label>
                  <Input
                    type="number"
                    placeholder="Size"
                    value={state.form.size}
                    onChange={e => {
                      e.persist();
                      setState(previewsState => {
                        return {
                          ...previewsState,
                          form: { ...previewsState.form, size: e.target.value }
                        };
                      });
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Description</Label>
                  <Editor
                    editorState={state.form.description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={editorState =>
                      setState(previewsState => {
                        return {
                          ...previewsState,
                          form: {
                            ...previewsState.form,
                            description: editorState
                          }
                        };
                      })
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            onClick={() =>
              setState(previewsState => {
                return { ...previewsState, modalActive: false };
              })
            }
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => (state.modalEdit ? updateBin() : submitBin())}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={state.deleteModalActive}
        size="md"
        toggle={() =>
          setState(previewsState => {
            return {
              ...previewsState,
              deleteModalActive: false
            };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return {
                ...previewsState,
                deleteModalActive: false
              };
            })
          }
        >
          Delete Bin {state.nameForDeleteModal}?
        </ModalHeader>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            onClick={() =>
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              })
            }
          >
            No
          </Button>
          <Button
            color="primary"
            onClick={() => {
              state.keyForDeleteModal &&
                props.firestore.delete(`bins/${state.keyForDeleteModal}`);
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              });
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default compose(
  firestoreConnect(["bins"]),
  withFirestore,
  connect(state => ({
    bins: state.firestore.data.bins
  }))
)(Bins);
