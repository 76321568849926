import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import {
  Container,
  Col,
  Row,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Kbd,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Option,
  H5,
  Alert
} from "@bootstrap-styled/v4";
import { Trash2 } from "styled-icons/feather/Trash2";
import { Edit2 } from "styled-icons/feather/Edit2";
import DayPicker, { DateUtils } from "react-day-picker";
import Select from "react-select";
import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Container as DragContainer, Draggable } from "react-smooth-dnd";

const Trash = styled(Trash2)`
  color: inherit;
`;

const Edit = styled(Edit2)`
  color: inherit;
`;

const Classes = props => {
  const [state, setState] = useState({
    modalActive: false,
    deleteModalActive: false,
    selectedDays: [],
    time: null,
    bins: "",
    cities: "",
    form: {
      type: "1",
      name: "",
      classBins: [],
      cities: []
    }
  });

  useEffect(() => {
    if (state.bins === "") {
      setState(previewsState => {
        return {
          ...previewsState,
          bins: props.bins
        };
      });
    }
    if (state.cities === "") {
      setState(previewsState => {
        return {
          ...previewsState,
          cities: props.cities
        };
      });
    }
  }, [state.bins, state.cities, props.bins, props.cities]);

  const handleDayClick = (day, { selected }) => {
    const { selectedDays } = state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setState(previewsState => {
      return {
        ...previewsState,
        selectedDays: selectedDays
      };
    });
  };

  const submitClass = () => {
    setState({
      modalActive: false,
      modalEdit: false,
      selectedDays: [],
      form: {
        type: "",
        name: "",
        classBins: [],
        cities: []
      }
    });
    props.firestore.add("classes", {
      ...state.form,
      time: state.time,
      storedBins: state.form.classBins.map(bin => bin.binInfo.key),
      selectedDays: state.selectedDays
    });
  };

  const updateClass = () => {
    setState({
      modalActive: false,
      modalEdit: false,
      selectedDays: [],
      form: {
        type: "",
        name: "",
        classBins: [],
        cities: []
      }
    });
    props.firestore.update(`classes/${state.key}`, {
      ...state.form,
      time: state.time,
      storedBins: state.form.classBins.map(bin => bin.binInfo.key),
      selectedDays: state.selectedDays
    });
  };

  const editClass = (key, priceClass) => {
    setState(previewsState => {
      return {
        ...previewsState,
        modalActive: true,
        modalEdit: true,
        selectedDays: [
          ...priceClass.selectedDays.map(timestamp => timestamp.toDate())
        ],
        key: key,
        time: priceClass.time && priceClass.time.toDate(),
        form: { ...priceClass }
      };
    });
  };

  const addBinToClass = () => {
    setState(previewsState => {
      return {
        ...previewsState,
        form: {
          ...previewsState.form,
          classBins: [
            ...previewsState.form.classBins,
            {
              bin: "",
              deposit: 0,
              rate: 0,
              disposal: 0,
              fuel: 0,
              note: ""
            }
          ]
        }
      };
    });
  };

  const removeBinFromClass = index => {
    setState(previewsState => {
      const classBins = [...previewsState.form.classBins];

      classBins.splice(index, 1);

      return {
        ...previewsState,
        form: {
          ...previewsState.form,
          classBins: [...classBins]
        }
      };
    });
  };

  const onBinDrop = dragResult =>
    setState(previewsState => {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null)
        return state.form.classBins;

      const result = [...state.form.classBins];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return {
        ...previewsState,
        form: {
          ...previewsState.form,
          classBins: [...result]
        }
      };
    });

  return (
    <Fragment>
      <Container>
        <Row className="pt-4 pb-4">
          <Col md={6} className="pb-3 d-flex align-items-center">
            <H5 color="gray-dark" className="mb-0">
              All Classes
            </H5>
          </Col>
          <Col md={6} className="pb-3 d-flex justify-content-end">
            <Button
              outline
              color="success"
              style={{ width: "140px" }}
              onClick={e =>
                setState(previewsState => {
                  return { ...previewsState, modalActive: true };
                })
              }
            >
              Add new class
            </Button>
          </Col>
          <Col md={12}>
            <Table bordered>
              <Thead>
                <Tr>
                  <Th />
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th>Bins</Th>
                  <Th>Cities</Th>
                  <Th>Dates</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {!!props.classes &&
                  Object.keys(props.classes).map((key, index) =>
                    props.classes[key] ? (
                      <Tr key={key}>
                        <Th scope="row" className="align-middle text-center">
                          <Kbd>{index + 1}</Kbd>
                        </Th>
                        <Td className="align-middle">
                          {props.classes[key].name}
                        </Td>
                        <Td className="align-middle">
                          {props.classes[key].type}
                        </Td>
                        <Td className="align-middle">
                          {props.classes[key].classBins.length}
                        </Td>
                        <Td className="align-middle">
                          {props.classes[key].cities.length}
                        </Td>
                        <Td className="align-middle">
                          {props.classes[key].selectedDays
                            ? props.classes[key].selectedDays.length
                            : "-"}
                        </Td>
                        <Td className="text-center" style={{ width: "150px" }}>
                          <Button
                            outline
                            color="primary"
                            style={{ margin: "0 4px" }}
                            onClick={e => editClass(key, props.classes[key])}
                          >
                            <Edit size={20} />
                          </Button>
                          <Button
                            outline
                            color="danger"
                            style={{ margin: "0 4px" }}
                            onClick={e =>
                              setState(previewsState => {
                                return {
                                  ...previewsState,
                                  deleteModalActive: true,
                                  keyForDeleteModal: key,
                                  nameForDeleteModal: props.classes[key].name
                                };
                              })
                            }
                          >
                            <Trash size={20} />
                          </Button>
                        </Td>
                      </Tr>
                    ) : null
                  )}
              </Tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={state.modalActive}
        size="lg"
        toggle={() =>
          setState({
            modalActive: false,
            modalEdit: false,
            selectedDays: [],
            form: {
              type: "1",
              name: "",
              classBins: [],
              cities: []
            }
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState({
              modalActive: false,
              modalEdit: false,
              selectedDays: [],
              form: {
                type: "1",
                name: "",
                classBins: [],
                cities: []
              }
            })
          }
        >
          {state.modalEdit ? "Edit Class" : "Add New Class"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <Label>Name</Label>
                        <Input
                          type="text"
                          placeholder="Name"
                          value={state.form.name}
                          onChange={e => {
                            e.persist();

                            setState(previewsState => {
                              return {
                                ...previewsState,
                                form: {
                                  ...previewsState.form,
                                  name: e.target.value
                                }
                              };
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <Label style={{ display: "block" }}>Time</Label>
                        <DatePicker
                          selected={state.time}
                          onChange={value => {
                            setState(previewsState => {
                              return {
                                ...previewsState,
                                time: value
                              };
                            });
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="h:mm aa"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    name="Type"
                    style={{ height: "calc(2.25rem + 2px)" }}
                    value={state.form.type}
                    onChange={e => {
                      e.persist();

                      setState(previewsState => {
                        return {
                          ...previewsState,
                          form: { ...previewsState.form, type: e.target.value }
                        };
                      });
                    }}
                  >
                    <Option>Please Select Class Type</Option>
                    <Option>1</Option>
                    <Option>2</Option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col md={12}>
                <Row style={{ marginBottom: "15px" }}>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <Label style={{ margin: "0" }}>Bins</Label>
                  </Col>
                  <Col md="6" style={{ textAlign: "right" }}>
                    <Button
                      outline
                      color="success"
                      style={{ width: "140px" }}
                      onClick={e => addBinToClass()}
                    >
                      Add bin to class
                    </Button>
                  </Col>
                </Row>
                <DragContainer onDrop={onBinDrop} lockAxis="y" behaviour="move">
                  {state.form.classBins.length > 0 ? (
                    state.form.classBins.map((bin, index) => (
                      <Draggable key={index}>
                        <Row
                          key={index}
                          style={{
                            margin: "0 0 15px",
                            border: "1px solid",
                            padding: "10px 0 0",
                            borderRadius: "6px"
                          }}
                        >
                          <Col md="3">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Bin</Label>
                                  <Input
                                    type="select"
                                    name="Bin"
                                    style={{ height: "calc(2.25rem + 2px)" }}
                                    value={state.form.classBins[index].bin}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        const binInfo = Object.entries(
                                          props.bins
                                        ).filter(
                                          bin =>
                                            bin[1].size +
                                              " Yards " +
                                              bin[1].type ===
                                            e.target.value
                                        );

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            bin: e.target.value,
                                            binInfo: {
                                              ...binInfo[0][1],
                                              key: binInfo[0][0]
                                            }
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  >
                                    <Option>Select Bin</Option>
                                    {!!props.bins &&
                                      Object.keys(props.bins).map(
                                        (key, index) => (
                                          <Option key={key}>
                                            {props.bins[key].size +
                                              " Yards " +
                                              props.bins[key].type}
                                          </Option>
                                        )
                                      )}
                                  </Input>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Deposit</Label>
                                  <Input
                                    type="number"
                                    placeholder="Deposit"
                                    value={state.form.classBins[index].deposit}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            deposit: e.target.value
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Rate</Label>
                                  <Input
                                    type="number"
                                    placeholder="Rate"
                                    value={state.form.classBins[index].rate}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            rate: e.target.value
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Disposal</Label>
                                  <Input
                                    type="number"
                                    placeholder="Disposal"
                                    value={state.form.classBins[index].disposal}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            disposal: e.target.value
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Fuel</Label>
                                  <Input
                                    type="number"
                                    placeholder="Fuel"
                                    value={state.form.classBins[index].fuel}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            fuel: e.target.value
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <Button
                              outline
                              color="danger"
                              style={{ width: "53px", marginTop: "28px" }}
                              onClick={e => removeBinFromClass(index)}
                            >
                              <Trash size={20} />
                            </Button>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Row>
                                <Col md={12}>
                                  <Label>Note</Label>
                                  <Input
                                    type="text"
                                    placeholder="Note"
                                    value={state.form.classBins[index].note}
                                    onChange={e => {
                                      e.persist();

                                      setState(previewsState => {
                                        const classBins = [
                                          ...previewsState.form.classBins
                                        ];

                                        classBins[index] = Object.assign(
                                          {},
                                          classBins[index],
                                          {
                                            note: e.target.value
                                          }
                                        );

                                        return {
                                          ...previewsState,
                                          form: {
                                            ...previewsState.form,
                                            classBins: [...classBins]
                                          }
                                        };
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Draggable>
                    ))
                  ) : (
                    <Alert color="info">No bins defined!</Alert>
                  )}
                </DragContainer>
              </Col>
            </Row>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Cities</Label>
                  {!!props.cities && (
                    <Select
                      options={Object.keys(props.cities).map(key => {
                        return {
                          label: props.cities[key].name,
                          value: props.cities[key].name,
                          selected: props.cities[key].selected
                        };
                      })}
                      value={state.form.cities.map(city => {
                        return {
                          label: city,
                          value: city,
                          selected: city.selected
                        };
                      })}
                      isOptionDisabled={option =>
                        option.selected !== undefined
                          ? option.selected !== false
                          : false
                      }
                      onChange={val => {
                        let value = val ? val.map(city => city.label) : [];

                        setState(previewsState => {
                          return {
                            ...previewsState,
                            form: {
                              ...previewsState.form,
                              cities: value
                            }
                          };
                        });
                      }}
                      isMulti
                    />
                  )}
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col md={12}>
                <Label>Availability</Label>
              </Col>
              <Col md={12}>
                <DayPicker
                  numberOfMonths={2}
                  selectedDays={state.selectedDays}
                  onDayClick={handleDayClick}
                  canChangeMonth={false}
                  showOutsideDays={false}
                  disabledDays={[
                    {
                      before: new Date()
                    }
                  ]}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            onClick={() =>
              setState({
                modalActive: false,
                selectedDays: [],
                form: {
                  type: "1",
                  classBins: [],
                  cities: []
                }
              })
            }
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => (state.modalEdit ? updateClass() : submitClass())}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={state.deleteModalActive}
        size="md"
        toggle={() =>
          setState(previewsState => {
            return {
              ...previewsState,
              deleteModalActive: false
            };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return {
                ...previewsState,
                deleteModalActive: false
              };
            })
          }
        >
          Delete Price Class {state.nameForDeleteModal}?
        </ModalHeader>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            onClick={() =>
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              })
            }
          >
            No
          </Button>
          <Button
            color="primary"
            onClick={() => {
              state.keyForDeleteModal &&
                props.firestore.delete(`classes/${state.keyForDeleteModal}`);
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              });
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default compose(
  firestoreConnect(["classes", "cities", "bins"]),
  withFirestore,
  connect(state => ({
    classes: state.firestore.data.classes,
    cities: state.firestore.data.cities,
    bins: state.firestore.data.bins
  }))
)(Classes);
