import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import { Container, Row, Col, Input } from "@bootstrap-styled/v4";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StripeCheckout from "react-stripe-checkout";
import Select from "react-select";
import StyledOrders from "./style";
import logo from "../img/logo.png";
import logoFooter from "../img/logo-hersey-footer.png";
import baumlerLogo from "../img/logo-Baumler-Approved.png";
import howie from "../img/howie.png";

const Orders = props => {
  const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const [state, setState] = useState({
    leaveListener: false,
    index: 0,
    binsModalActive: false,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    city: "",
    property: "",
    wasteType: null,
    material: "",
    selectedBin: "",
    selectedBinInfo: null,
    deliveryDate: "",
    removalDate: "",
    timeOfDay: "",
    driveway: "",
    drivewaySide: "",
    drivewayCustom: "",
    additionalNotes: "",
    companyName: "",
    streetAddress: "",
    apartment: "",
    province: "",
    postalCode: "",
    orderConfirmed: false,
    stripeButtonClicked: false
  });

  useEffect(() => {
    if (!state.leaveListener) {
      setState(previewsState => {
        return {
          ...previewsState,
          leaveListener: true
        };
      });
    }
    if (state.index === 2 && props.classes && state.selectedBin === "") {
      setState(previewsState => {
        return {
          ...previewsState,
          selectedBin: 0,
          selectedBinInfo: getBins()[0]
        };
      });
    }
  }, [state, props.classes]);

  const placeOrder = e => {
    e.preventDefault();
    if (isStepValid(5)) {
      document.getElementById("placeOrderButton").click();
    } else {
      setState(previewsState => {
        return {
          ...previewsState,
          stripeButtonClicked: true
        };
      });
    }
  };

  const setIndex = index => {
    window.scrollTo(0, 0);
    setState(previewsState => {
      return {
        ...previewsState,
        index: index
      };
    });

    if (index === 1) {
      props.firestore.setListener({
        collection: "classes",
        where: ["cities", "array-contains", state.city]
      });
    }
  };

  const setWasteType = wasteType =>
    setState(previewsState => {
      return {
        ...previewsState,
        wasteType: wasteType
      };
    });

  const setFormValue = (field, value) => {
    setState(previewsState => {
      return {
        ...previewsState,
        [field]: value
      };
    });
  };

  const setModal = () =>
    setState(previewsState => {
      return {
        ...previewsState,
        binsModalActive: !previewsState.binsModalActive
      };
    });

  const isStepValid = stepIndex => {
    if (stepIndex === 0) {
      return (
        state.firstName !== "" &&
        state.lastName !== "" &&
        state.phoneNumber !== "" &&
        state.phoneNumber.length >= 10 &&
        state.emailAddress !== "" &&
        emailRegex.test(state.emailAddress) &&
        state.city !== ""
      );
    }

    if (stepIndex === 1) {
      return (
        (state.property !== "" &&
          state.wasteType === 1 &&
          state.material !== "") ||
        (state.property !== "" && state.wasteType === 0)
      );
    }

    if (stepIndex === 2) {
      return state.selectedBin !== "";
    }

    if (stepIndex === 3) {
      return (
        (state.deliveryDate !== "" &&
          state.removalDate !== "" &&
          state.timeOfDay !== "" &&
          state.driveway !== "" &&
          state.drivewaySide !== "") ||
        (state.deliveryDate !== "" &&
          state.removalDate !== "" &&
          state.timeOfDay !== "" &&
          state.driveway === "Single car driveway") ||
        (state.deliveryDate !== "" &&
          state.removalDate !== "" &&
          state.timeOfDay !== "" &&
          state.driveway === "Other")
      );
    }

    if (stepIndex === 4) {
      return state.orderConfirmed;
    }

    if (stepIndex === 5) {
      return (
        state.firstName !== "" &&
        state.lastName !== "" &&
        state.streetAddress !== "" &&
        state.province !== "" &&
        state.postalCode !== "" &&
        state.phoneNumber !== "" &&
        state.phoneNumber.length >= 10 &&
        state.emailAddress !== ""
      );
    }
  };

  const selectBin = binIndex =>
    props.classes &&
    setState(previewsState => {
      return {
        ...previewsState,
        selectedBin: binIndex,
        selectedBinInfo: getBins()[binIndex]
      };
    });

  const onToken = token => {
    setState(previewsState => {
      const newState = {
        ...previewsState,
        index: 6,
        orderStatus: "new",
        stripeToken: token
      };

      props.firestore.add("orders", {
        ...newState,
        createdAt: new Date()
      });

      return newState;
    });
  };

  const getBins = () => {
    if (state.wasteType === 1) {
      return Object.entries(props.classes)[0][1]
        .classBins.slice()
        .sort((a, b) => a.binInfo.size - b.binInfo.size)
        .filter(bin => {
          return (
            (bin.binInfo.type !== "Normal" &&
              bin.binInfo.property === state.property) ||
            (bin.binInfo.type !== "Normal" && bin.binInfo.property === "Both")
          );
        });
    } else {
      return Object.entries(props.classes)[0][1]
        .classBins.slice()
        .sort((a, b) => a.binInfo.size - b.binInfo.size)
        .filter(bin => {
          if (state.property === "Both") {
            return bin.binInfo.type === "Normal";
          } else {
            return (
              (bin.binInfo.type === "Normal" &&
                bin.binInfo.property === state.property) ||
              (bin.binInfo.type === "Normal" && bin.binInfo.property === "Both")
            );
          }
        });
    }
  };

  return (
    <Beforeunload
      onBeforeunload={event => {
        if (
          state.index !== 6 &&
          state.firstName !== "" &&
          state.lastName !== "" &&
          state.phoneNumber !== "" &&
          // state.phoneNumber.length >= 10 &&
          state.emailAddress !== "" &&
          state.city !== ""
        ) {
          window.swBusLane.active.postMessage({
            message: "saveOrder",
            state: state
          });
        }

        // return "asd";
      }}
    >
      <StyledOrders>
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only"> Toggle navigation</span>
                <span className="icon-bar"> </span>
                <span className="icon-bar"> </span>
                <span className="icon-bar"> </span>
              </button>
              <a
                className="navbar-brand"
                href="https://herseybins.ca/index.html"
              >
                <img src={logo} height="65" alt="" />
              </a>
              <p className="navbar-text">
                <a
                  style={{
                    background: "#fff",
                    color: "#000",
                    textDecoration: "none"
                  }}
                  href="tel:1-855-543-7739"
                >
                  1-855-5-HERSEY
                </a>
                <br />
                <a
                  className="btn btn-default navbar-btn red"
                  href="https://orders.herseybins.ca"
                  role="button"
                >
                  BOOK NOW
                </a>
              </p>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="https://herseybins.ca/index.html" data-id="home">
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/about-hersey-bins.html"
                    data-id="about-us"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/bin-rental.html"
                    data-id="bin-rental"
                  >
                    Bin Rental
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/commercial-dumpsters.html"
                    data-id="commercial-dumpsters"
                  >
                    Commercial Dumpsters
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/how-it-works.html"
                    data-id="how-it-works"
                  >
                    How It Works
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/testimonials.html"
                    data-id="testimonials"
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <a
                    href="https://herseybins.ca/contact.html"
                    data-id="contact-us"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="steps-navigation">
          <ul>
            <li
              className={state.index === 0 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(0) && setIndex(0);
              }}
            >
              1
            </li>
            <li
              className={state.index === 1 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(1) && setIndex(1);
              }}
            >
              2
            </li>
            <li
              className={state.index === 2 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(2) && setIndex(2);
              }}
            >
              3
            </li>
            <li
              className={state.index === 3 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(3) && setIndex(3);
              }}
            >
              4
            </li>
            <li
              className={state.index === 4 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(4) && setIndex(4);
              }}
            >
              5
            </li>
            <li
              className={state.index === 5 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(5) && setIndex(5);
              }}
            >
              6
            </li>
            <li
              className={state.index === 6 ? "active" : ""}
              onClick={e => {
                e.preventDefault();
                isStepValid(6) && setIndex(6);
              }}
            >
              7
            </li>
          </ul>
        </div>
        <div className="steps-form">
          {state.index === 0 && (
            <Container>
              <Col md={{ offset: 3, size: 6 }}>
                <h1>LET’S GET STARTED!</h1>
                <p>
                  Contact us today and one of our friendly staff will promptly
                  respond to any requests you may have.
                </p>

                <Row>
                  <Col md="6">
                    <input
                      className="form-control"
                      placeholder="First Name"
                      value={state.firstName}
                      onChange={e => setFormValue("firstName", e.target.value)}
                    />
                  </Col>
                  <Col md="6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={state.lastName}
                      onChange={e => setFormValue("lastName", e.target.value)}
                    />
                  </Col>
                  <Col md="6">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone Number"
                      value={state.phoneNumber}
                      onChange={e => {
                        var telRegex = /^-?\d*\.?\d*$/;

                        telRegex.test(e.target.value) &&
                          setFormValue("phoneNumber", e.target.value);
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      value={state.emailAddress}
                      onChange={e =>
                        setFormValue("emailAddress", e.target.value)
                      }
                    />
                  </Col>
                  <Col md="12">
                    {/* <Input
                    key={state.city}
                    className="form-control"
                    type="select"
                    name="City"
                    value={state.city}
                    onChange={e => setFormValue("city", e.target.value)}
                  >
                    <Option>Select City</Option>
                    {!!props.cities &&
                      Object.keys(props.cities).map((key, index) => (
                        <Option key={key}>{props.cities[key].name}</Option>
                      ))}
                  </Input> */}

                    {props.cities && (
                      <Select
                        className="custom-select"
                        name="city"
                        placeholder="Select City"
                        value={
                          state.city !== ""
                            ? {
                                value: state.city,
                                label: state.city
                              }
                            : ""
                        }
                        onChange={val => setFormValue("city", val.value)}
                        options={Object.keys(props.cities).map(key => {
                          return {
                            label: props.cities[key].name,
                            value: props.cities[key].name
                          };
                        })}
                      />
                    )}
                  </Col>
                </Row>
                <code>
                  Don’t see your city listed here? Contact us at 1-855-5-HERSEY
                  and one of our specialists will assist you!
                </code>
                <button
                  className="btn btn-lg btn-block red submit"
                  type="submit"
                  onClick={e => setIndex(1)}
                  disabled={!isStepValid(0)}
                >
                  NEXT >
                </button>
              </Col>
            </Container>
          )}
          {state.index === 1 && (
            <Container>
              <Col md={{ offset: 3, size: 6 }}>
                <Row>
                  <Col md="12">
                    <h1
                      style={{
                        marginBottom: "50px",
                        width: "140%",
                        marginLeft: "-20%",
                        lineHeight: "60px"
                      }}
                    >
                      IS THE BIN GOING TO A COMMERCIAL OR RESIDENTIAL PROPERTY?
                    </h1>
                  </Col>
                  <Col md="12">
                    <Select
                      className="custom-select"
                      name="property"
                      placeholder="Select an option"
                      value={
                        state.property !== ""
                          ? {
                              value: state.property,
                              label: state.property
                            }
                          : ""
                      }
                      onChange={val => setFormValue("property", val.value)}
                      options={[
                        {
                          value: "Residential",
                          label: "Residential"
                        },
                        {
                          value: "Commercial",
                          label: "Commercial"
                        }
                      ]}
                    />
                  </Col>
                  <Col md="12">
                    <h1
                      style={{
                        marginBottom: "50px",
                        width: "140%",
                        marginLeft: "-20%",
                        lineHeight: "60px"
                      }}
                    >
                      WHAT TYPE OF WASTE ARE YOU DISPOSING OF?
                    </h1>
                  </Col>
                  <Col md="12">
                    <button
                      style={{ marginBottom: "30px" }}
                      className={
                        "btn btn-lg btn-block red submit waste-type " +
                        (state.wasteType === 0 ? "selected" : "")
                      }
                      type="submit"
                      onClick={e => setWasteType(0)}
                    >
                      WASTE / JUNK
                    </button>
                    <button
                      style={{ marginBottom: "30px" }}
                      className={
                        "btn btn-lg btn-block red submit waste-type " +
                        (state.wasteType === 1 ? "selected" : "")
                      }
                      type="submit"
                      onClick={e => setWasteType(1)}
                    >
                      CONCRETE / CLEANFILL
                    </button>
                  </Col>
                  {state.wasteType === 1 && (
                    <Fragment>
                      <Col md="12">
                        <h1
                          style={{
                            marginBottom: "50px",
                            width: "140%",
                            marginLeft: "-20%",
                            lineHeight: "60px"
                          }}
                        >
                          WHAT KIND OF CONCRETE / SOIL ARE YOU DISPOSING OF?
                        </h1>
                      </Col>
                      <Col md="12">
                        <Select
                          className="custom-select"
                          name="material"
                          placeholder="Select an option"
                          value={
                            state.material !== ""
                              ? {
                                  value: state.material,
                                  label: state.material
                                }
                              : ""
                          }
                          onChange={val => setFormValue("material", val.value)}
                          options={[
                            {
                              value: "Asphalt",
                              label: "Asphalt"
                            },
                            {
                              value: "Brick",
                              label: "Brick"
                            },
                            {
                              value: "Concrete",
                              label: "Concrete"
                            },
                            {
                              value: "Cleanfill",
                              label: "Cleanfill"
                            },
                            {
                              value: "Top Soil",
                              label: "Top Soil"
                            },
                            {
                              value: "Sod",
                              label: "Sod"
                            }
                          ]}
                        />
                      </Col>
                    </Fragment>
                  )}
                </Row>
                <button
                  className="btn btn-lg btn-block red submit"
                  type="submit"
                  onClick={e => setIndex(2)}
                  disabled={!isStepValid(1)}
                >
                  NEXT >
                </button>
              </Col>
            </Container>
          )}
          {state.index === 2 && (
            <Fragment>
              <h1
                style={{
                  marginBottom: "50px",
                  width: "200%",
                  marginTop: "0",
                  marginLeft: "-50%",
                  lineHeight: "60px"
                }}
              >
                PLEASE SELECT A BIN THAT FITS YOUR NEED
              </h1>
              {!!props.classes &&
                getBins().map((bin, index) => {
                  return (
                    <div key={bin.binInfo.key} className="slide">
                      <img src={bin.binInfo.previewImage} alt="" />
                      <div className="slider-description">
                        <h2>
                          <span>{bin.binInfo.size} Yard</span> Bin Rental
                        </h2>
                        <div className="slider-details">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: bin.binInfo.description
                            }}
                          />
                        </div>
                      </div>
                      <div className="slider-summary">
                        <code>
                          <div>
                            <b>PRICING</b>
                            {bin.rate &&
                              (bin.rate !== "0" || bin.rate !== 0) && (
                                <div>${bin.rate} bin rental</div>
                              )}
                            {bin.disposal &&
                            (bin.disposal !== "0" || bin.disposal !== 0) ? (
                              <div>${bin.disposal}/ton disposal</div>
                            ) : (
                              ""
                            )}
                            {bin.fuel &&
                            (bin.fuel !== "0" || bin.fuel !== 0) ? (
                              <div>${bin.fuel} fuel</div>
                            ) : (
                              ""
                            )}
                            {bin.deposit &&
                              (bin.deposit !== "0" || bin.deposit !== 0) && (
                                <div>${bin.deposit} Deposit</div>
                              )}
                          </div>
                        </code>
                        {bin.note && (
                          <p
                            style={{
                              textAlign: "left",
                              margin: "-10px 0 10px"
                            }}
                          >
                            {bin.note}
                          </p>
                        )}
                        <button
                          className="btn btn-lg btn-block red submit"
                          type="submit"
                          onClick={e => {
                            selectBin(index);
                            setModal();
                          }}
                          disabled={!isStepValid(2)}
                        >
                          SELECT THIS BIN >
                        </button>
                      </div>
                    </div>
                  );
                })}
              {/* <Row>
                <Col md="6">
                  <button
                    className="btn btn-lg btn-block red submit"
                    type="submit"
                    onClick={e => setIndex(1)}
                  >
                    {"< GO BACK"}
                  </button>
                </Col>
                <Col md="6">
                  
                </Col>
              </Row> */}
              {state.binsModalActive && (
                <div className="bins-modal">
                  <div>
                    <h2>YOU HAVE SELECTED</h2>
                    <p>{state.selectedBinInfo.binInfo.size} Yard Bin Rental</p>
                    <Row>
                      <Col md="12">
                        <button
                          className="btn btn-lg btn-block red submit"
                          type="submit"
                          onClick={e => setIndex(3)}
                        >
                          {"PROCEED"}
                        </button>
                      </Col>
                      <Col md="12">
                        <button
                          className="btn btn-lg btn-block red submit cancel"
                          type="submit"
                          onClick={e => setModal()}
                        >
                          {"GO BACK"}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {state.index === 3 && (
            <Container>
              <Col md={{ offset: 2, size: 8 }}>
                <h1
                  style={{
                    marginBottom: "50px",
                    width: "95%",
                    marginLeft: "2.5%",
                    lineHeight: "60px"
                  }}
                >
                  CHOOSE THE DATE OF DELIVERY AND REMOVAL
                </h1>
                <Row>
                  <Col
                    md="6"
                    className="delivery-date"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <label
                      style={{ whiteSpace: "nowrap", margin: "0 10px 0 0" }}
                    >
                      Delivery date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={state.deliveryDate}
                      onChange={date => setFormValue("deliveryDate", date)}
                      minDate={new Date()}
                      includeDates={Object.entries(
                        props.classes
                      )[0][1].selectedDays.map(timestamp => {
                        if (
                          `${new Date().getDate()}/${new Date().getMonth()}` ===
                            `${new Date(
                              timestamp.toDate()
                            ).getDate()}/${new Date(
                              timestamp.toDate()
                            ).getMonth()}` &&
                          new Date().getHours() >=
                            new Date(
                              Object.entries(props.classes)[0][1].time.toDate()
                            ).getHours()
                        ) {
                        } else {
                          return timestamp.toDate();
                        }
                      })}
                      placeholderText="Delivery date"
                    />
                  </Col>
                  <Col
                    md="6"
                    className="delivery-date"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <label
                      style={{ whiteSpace: "nowrap", margin: "0 10px 0 0" }}
                    >
                      Removal date
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={state.removalDate}
                      minDate={
                        state.deliveryDate === "" || state.deliveryDate === null
                          ? new Date()
                          : state.deliveryDate
                      }
                      onChange={date => {
                        setFormValue("removalDate", date);
                      }}
                      includeDates={Object.entries(
                        props.classes
                      )[0][1].selectedDays.map(timestamp => timestamp.toDate())}
                      placeholderText="Removal date"
                    />
                  </Col>
                  <Col md="12">
                    <p style={{ fontWeight: "bold" }}>
                      What time of day would you like the bin delivered?
                    </p>
                  </Col>
                  <Col md="12">
                    <Select
                      className="custom-select"
                      name="timeOfDay"
                      placeholder="Select an option"
                      value={
                        state.timeOfDay !== ""
                          ? {
                              value: state.timeOfDay,
                              label: state.timeOfDay
                            }
                          : ""
                      }
                      onChange={val => setFormValue("timeOfDay", val.value)}
                      options={[
                        {
                          value: "Anytime",
                          label: "Anytime"
                        },
                        {
                          value: "7am - 12pm",
                          label: "7am - 12pm"
                        },
                        {
                          value: "12pm - 6pm",
                          label: "12pm - 6pm"
                        }
                      ]}
                    />
                  </Col>
                  <Col md="12">
                    <p style={{ marginTop: "0", fontWeight: "bold" }}>
                      Where will the bin be placed on the property?
                    </p>
                  </Col>
                  <Col md="12">
                    <Select
                      className="custom-select"
                      name="driveway"
                      placeholder="Select an option"
                      value={
                        state.driveway !== ""
                          ? {
                              value: state.driveway,
                              label: state.driveway
                            }
                          : ""
                      }
                      onChange={val => setFormValue("driveway", val.value)}
                      options={[
                        {
                          value: "Single car driveway",
                          label: "Single car driveway"
                        },
                        {
                          value: "Double car driveway",
                          label: "Double car driveway"
                        },
                        {
                          value: "Other",
                          label: "Other"
                        }
                      ]}
                    />
                  </Col>
                  {state.driveway === "Double car driveway" && (
                    <Fragment>
                      <Col md="12">
                        <p style={{ marginTop: "0", fontWeight: "bold" }}>
                          When your facing the drive way
                          <br /> which side would you like the bin placed?
                        </p>
                      </Col>
                      <Col md="12">
                        <Select
                          className="custom-select"
                          name="driveway"
                          placeholder="Select an option"
                          value={
                            state.drivewaySide !== ""
                              ? {
                                  value: state.drivewaySide,
                                  label: state.drivewaySide
                                }
                              : ""
                          }
                          onChange={val =>
                            setFormValue("drivewaySide", val.value)
                          }
                          options={[
                            {
                              value: "Right Hand Side",
                              label: "Right Hand Side"
                            },
                            {
                              value: "Left Hand Side",
                              label: "Left Hand Side"
                            }
                          ]}
                        />
                        {state.drivewaySide === "Other" && (
                          <Input
                            type="text"
                            placeholder="Prefered Location"
                            style={{ width: "300px", margin: "0 auto 30px" }}
                            className="form-control"
                            value={state.drivewayCustom}
                            onChange={e =>
                              setFormValue("drivewayCustom", e.target.value)
                            }
                          />
                        )}
                      </Col>
                    </Fragment>
                  )}
                  <Col md="12">
                    <textarea
                      value={state.additionalNotes}
                      onChange={e =>
                        setFormValue("additionalNotes", e.target.value)
                      }
                      style={{ height: "100px", margin: "0 0 20px" }}
                      className="form-control"
                      placeholder="Additional notes"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <button
                      className="btn btn-lg btn-block red submit"
                      type="submit"
                      onClick={e => setIndex(2)}
                    >
                      {"< GO BACK"}
                    </button>
                  </Col>
                  <Col md="6">
                    <button
                      className="btn btn-lg btn-block red submit"
                      type="submit"
                      onClick={e => setIndex(4)}
                      disabled={!isStepValid(3)}
                    >
                      {"NEXT >"}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Container>
          )}
          {state.index === 4 && (
            <Container>
              <Col md={{ offset: 2, size: 8 }}>
                <h1
                  style={{
                    marginBottom: "50px",
                    width: "95%",
                    marginLeft: "2.5%",
                    lineHeight: "60px"
                  }}
                >
                  ORDER CONFIRMATION
                </h1>
                <Row>
                  <Col md="6">
                    <ul className="order-confirmation">
                      <li>
                        Bin type:
                        <span>
                          {state.wasteType === 0
                            ? "Junk/Mixed Waste"
                            : "Concrete / Soil"}
                        </span>
                      </li>
                      <li>
                        Bin size:
                        <span>{state.selectedBinInfo.binInfo.size} Yard</span>
                      </li>
                      <li>
                        Delivery location:
                        <span>{state.city}</span>
                      </li>
                      <li>
                        Bin purpose:
                        <span>{state.property}</span>
                      </li>
                      <li>
                        Bin placement:
                        <span>{state.drivewaySide}</span>
                      </li>
                      <li>
                        Delivery date:
                        <span>
                          {new Date(state.deliveryDate).toDateString()}
                        </span>
                      </li>
                      <li>
                        Delivery time:
                        <span>{state.timeOfDay}</span>
                      </li>
                      <li>
                        Removal date:
                        <span>
                          {new Date(state.removalDate).toDateString()}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col md="6" style={{ textAlign: "left" }}>
                    <ul className="order-confirmation">
                      <li>
                        Rate:
                        <span>${state.selectedBinInfo.rate} bin rental</span>
                      </li>
                      <li>
                        <span>${state.selectedBinInfo.disposal} Disposal</span>
                      </li>
                      <li>
                        <span>${state.selectedBinInfo.fuel} Fuel</span>
                      </li>
                      <li>
                        <span>${state.selectedBinInfo.deposit} Deposit</span>
                      </li>
                      <li>
                        Additional notes:
                        <span>{state.additionalNotes}</span>
                      </li>
                    </ul>
                    <label className="order-confirmation-toggle">
                      <input
                        type="checkbox"
                        checked={state.orderConfirmed}
                        onChange={e =>
                          setFormValue("orderConfirmed", e.target.checked)
                        }
                        style={{
                          display: "inline-block",
                          margin: "0 5px 0 0",
                          padding: "0",
                          height: "13px"
                        }}
                      />{" "}
                      {props.siteSettings &&
                        props.siteSettings.disclaimer.content}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <button
                      className="btn btn-lg btn-block red submit"
                      type="submit"
                      onClick={e => setIndex(3)}
                    >
                      {"< GO BACK"}
                    </button>
                  </Col>
                  <Col md="6">
                    <button
                      className="btn btn-lg btn-block red submit"
                      type="submit"
                      onClick={e => setIndex(5)}
                      disabled={!isStepValid(4)}
                    >
                      {"CONFIRM ORDER >"}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Container>
          )}
          {state.index === 5 && (
            <Container>
              <Col md={{ size: 12 }}>
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="12">
                        <h3>Delivery & Billing Details</h3>
                      </Col>
                      <Col md="6">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked && state.firstName === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="First Name"
                          value={state.firstName}
                          onChange={e =>
                            setFormValue("firstName", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="6">
                        <input
                          type="text"
                          className={
                            "form-control " +
                            (state.stripeButtonClicked && state.lastName === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Last Name"
                          value={state.lastName}
                          onChange={e =>
                            setFormValue("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className="form-control"
                          placeholder="Company Name (optional)"
                          value={state.companyName}
                          onChange={e =>
                            setFormValue("companyName", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked &&
                            state.streetAddress === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Street Address"
                          value={state.streetAddress}
                          onChange={e =>
                            setFormValue("streetAddress", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className="form-control"
                          placeholder="Apartment, suite, unit etc. (optional)"
                          value={state.apartment}
                          onChange={e =>
                            setFormValue("apartment", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked && state.city === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Town/City"
                          value={state.city}
                          onChange={e => setFormValue("city", e.target.value)}
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked && state.province === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Province"
                          value={state.province}
                          onChange={e =>
                            setFormValue("province", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked &&
                            state.postalCode === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Postal Code"
                          value={state.postalCode}
                          onChange={e =>
                            setFormValue("postalCode", e.target.value)
                          }
                        />
                      </Col>
                      <Col md="6">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked &&
                            state.phoneNumber === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          type="number"
                          placeholder="Phone Number"
                          value={state.phoneNumber}
                          onChange={e => {
                            var telRegex = /^-?\d*\.?\d*$/;

                            telRegex.test(e.target.value) &&
                              setFormValue("phoneNumber", e.target.value);
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <input
                          className={
                            "form-control " +
                            (state.stripeButtonClicked &&
                            state.emailAddress === ""
                              ? "form-control-invalid"
                              : "")
                          }
                          placeholder="Email address"
                          value={state.emailAddress}
                          onChange={e =>
                            setFormValue("emailAddress", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <div className="order-summary">
                      <div className="order-summary-head">
                        <h2>Order Summary</h2>
                      </div>
                      <div className="order-summary-content">
                        <ul>
                          <li>
                            {state.selectedBinInfo &&
                              state.selectedBinInfo.binInfo.size}{" "}
                            Yard
                            {state.wasteType === 0
                              ? "Junk/Mixed Waste"
                              : "Concrete / Soil"}
                            <span>
                              $
                              {state.selectedBinInfo &&
                                state.selectedBinInfo.deposit}
                            </span>
                          </li>
                        </ul>
                        <StripeCheckout
                          name="Payment Information"
                          token={onToken}
                          label="PLACE ORDER"
                          currency="CAD"
                          amount={
                            state.selectedBinInfo &&
                            state.selectedBinInfo.deposit * 100
                          }
                          allowRememberMe={false}
                          email={state.emailAddress}
                          stripeKey="pk_live_2I0eEJWUBAL1WsEkW4oQ9zwM00yqzpUmYp"
                          // stripeKey="pk_test_JvnjfQYECbsHPbMmLtjegOXk00lj2vT6yw"
                        >
                          <button
                            id="placeOrderButton"
                            className="btn btn-block red submit"
                            style={{ display: "none" }}
                            type="submit"
                          >
                            {"PLACE ORDER"}
                          </button>
                        </StripeCheckout>
                        <button
                          className="btn btn-block red submit"
                          type="submit"
                          onClick={e => placeOrder(e)}
                          disabled={
                            state.stripeButtonClicked && !isStepValid(5)
                          }
                        >
                          {"PLACE ORDER"}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Container>
          )}
          {state.index === 6 && (
            <Container>
              <Col md={{ size: 12 }}>
                <Row>
                  <Col md="12">
                    <div className="order-completed">
                      <h2>ALL DONE!</h2>
                      <img src={howie} alt="" />
                      <h3>
                        THANK YOU FOR
                        <br /> CHOOSING HERSEY BINS!
                      </h3>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Container>
          )}
        </div>
        <div className="footer">
          <div className="container">
            <ul>
              <li>
                <a href="https://herseybins.ca/index.html" data-id="home">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/about-hersey-bins.html"
                  data-id="about-us"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/bin-rental.html"
                  data-id="bin-rental"
                >
                  Bin Rental
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/commercial-dumpsters.html"
                  data-id="commercial-dumpsters"
                >
                  Commercial Dumpsters
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/how-it-works.html"
                  data-id="how-it-works"
                >
                  How It Works
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/testimonials.html"
                  data-id="testimonials"
                >
                  Testimonials
                </a>
              </li>
              <li>
                <a
                  href="https://herseybins.ca/contact.html"
                  data-id="contact-us"
                >
                  Contact Us
                </a>
              </li>
              <li className="social">
                <a
                  href="https://www.facebook.com/herseybins"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="socialfacebook2" />
                </a>
              </li>
              <li className="social">
                <a
                  href="https://twitter.com/herseybins"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="socialtwitter" />
                </a>
              </li>
              <li className="social">
                <a
                  href="https://www.facebook.com/herseybins"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="socialgoogle-plus2" />
                </a>
              </li>
            </ul>

            <p className="well-lg">
              <a
                className="navbar-brand0"
                href="https://herseybins.ca/index.html"
              >
                <img src={logoFooter} height="85" alt="" />
              </a>
              <img src={baumlerLogo} height="85" alt="" />
            </p>

            <p className="disclaimer">
              Website/Hosting by{" "}
              <a
                href="http://montanapublishing.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Montana Website Design & Hosting
              </a>
              <span>&copy;2017 Hersey Bins | All rights reserved</span>
            </p>
          </div>
        </div>
      </StyledOrders>
    </Beforeunload>
  );
};

export default compose(
  firestoreConnect([
    { collection: "cities", orderBy: "name" },
    { collection: "siteSettings" }
  ]),
  withFirestore,
  connect(state => ({
    cities: state.firestore.data.cities,
    classes: state.firestore.data.classes,
    siteSettings: state.firestore.data.siteSettings
  }))
)(Orders);
