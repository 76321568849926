import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  withFirebase,
  isLoaded,
  isEmpty,
  withFirestore,
  firestoreConnect
} from "react-redux-firebase";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import styled from "styled-components";
import Classes from "./classes";
import Cities from "./cities";
import Bins from "./bins";
import Orders from "./orders";
import IncompleteOrders from "./incompleteOrders";
import {
  Container,
  A,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarToggler,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "@bootstrap-styled/v4";

const StyledSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: rgb(2, 117, 216);
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 1px;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  padding: 0 15px;
  color: inherit;

  &:hover,
  &.selected {
    color: #0275d8;
  }
`;

const Protected = ({ firestore, firebase, auth, history, siteSettings }) => {
  const [state, setState] = useState({
    modalActive: false,
    disclaimer: "",
    siteSettingsLoaded: false
  });

  useEffect(() => {
    if (siteSettings && siteSettings.disclaimer && !state.siteSettingsLoaded) {
      setState(previewsState => {
        return {
          ...previewsState,
          disclaimer: siteSettings.disclaimer.content,
          siteSettingsLoaded: true
        };
      });
    }
    if (isAuthenticated()) {
      history.push("/admin/auth");
    }
  }, [state, siteSettings, history]);

  const isAuthenticated = () => isLoaded(auth) && isEmpty(auth);

  const setFormValue = (field, value) => {
    setState(previewsState => {
      return {
        ...previewsState,
        [field]: value
      };
    });
  };

  return !isLoaded(auth) ? (
    <StyledSpinner>
      <div className="spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    </StyledSpinner>
  ) : !isAuthenticated() ? (
    <Fragment>
      <Navbar
        light
        toggleable="lg"
        style={{
          borderBottom: "1px solid #e5e5e5",
          padding: "15px 0"
        }}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <NavbarBrand tag={A} className="text-dark">
              Hersey Bins
            </NavbarBrand>
            <NavbarToggler />
          </div>
          <Nav className="mr-auto">
            <NavItem>
              <StyledLink activeClassName="selected" to="/admin/price-classes">
                Price Classes
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink activeClassName="selected" to="/admin/cities">
                Cities
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink activeClassName="selected" to="/admin/bins">
                Bins
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink activeClassName="selected" to="/admin/orders">
                Orders
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink
                activeClassName="selected"
                to="/admin/incomplete-orders"
              >
                Incomplete Orders
              </StyledLink>
            </NavItem>
            <NavItem>
              <StyledLink
                activeClassName="selected"
                to="/admin/incomplete-orders"
                onClick={e => {
                  e.preventDefault();
                  setState(previewsState => {
                    return {
                      ...previewsState,
                      modalActive: true
                    };
                  });
                }}
              >
                Site Settings
              </StyledLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto">
            <Button
              outline
              color="danger"
              style={{ width: "140px" }}
              onClick={e => firebase.logout()}
            >
              Sign Out
            </Button>
          </Nav>
        </Container>
      </Navbar>
      <Switch>
        <Route
          exact
          path="/admin"
          render={() => <Redirect to="/admin/price-classes" />}
        />
        <Route exact path="/admin/price-classes" component={Classes} />
        <Route exact path="/admin/cities" component={Cities} />
        <Route exact path="/admin/bins" component={Bins} />
        <Route exact path="/admin/orders" component={Orders} />
        <Route
          exact
          path="/admin/incomplete-orders"
          component={IncompleteOrders}
        />
      </Switch>
      <Modal
        isOpen={state.modalActive}
        size="lg"
        toggle={() =>
          setState(previewsState => {
            return { ...previewsState, modalActive: false };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return { ...previewsState, modalActive: false };
            })
          }
        >
          Site Settings
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <input
                className="form-control"
                placeholder="Disclaimer"
                value={state.disclaimer}
                onChange={e => setFormValue("disclaimer", e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="primary"
            onClick={() =>
              setState(previewsState => {
                return { ...previewsState, modalActive: false };
              })
            }
          >
            Close
          </Button>
          <Button
            color="success"
            onClick={() => {
              setState(previewsState => {
                firestore.update(`siteSettings/disclaimer`, {
                  content: state.disclaimer
                });
                return { ...previewsState, modalActive: false };
              });
            }}
          >
            Save Settings
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  siteSettings: state.firestore.data.siteSettings
});

export default compose(
  firestoreConnect([{ collection: "siteSettings" }]),
  withFirestore,
  withFirebase,
  connect(
    mapStateToProps,
    null
  )
)(Protected);
