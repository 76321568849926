import React, { Fragment, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import styled from "styled-components";
import {
  Container,
  Col,
  Row,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Kbd,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  H5,
  Badge
} from "@bootstrap-styled/v4";

const OrderDetails = styled.div`
  ul.order-confirmation {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: flex-startl
      color: #4c4c4e;
      font-weight: bold;
      text-align: left;
      line-height: 2.4;
      padding: 0 10px;

      &:nth-child(even) {
        background-color: #f5f5f6;
      }

      span {
        width: 200px;
        font-weight: normal;
        margin-left: auto;
      }
    }
  }
`;

const Orders = props => {
  const [state, setState] = useState({
    modalActive: false
  });

  useEffect(() => {});

  return (
    <Fragment>
      <Container>
        <Row className="pt-4 pb-4">
          <Col
            md={12}
            className="pb-3 d-flex align-items-center"
            style={{ height: "54px" }}
          >
            <H5 color="gray-dark" className="mb-0">
              All Incompleted Orders
            </H5>
          </Col>
          <Col md={12}>
            <Table bordered>
              <Thead>
                <Tr>
                  <Th />
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                  <Th>Location</Th>
                  <Th>Bin Size</Th>
                  <Th>Deposit</Th>
                  <Th>Delivery Date</Th>
                  <Th>Created At</Th>
                  <Th>Status</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {!!props.orders &&
                  Object.keys(props.orders).map((key, index) => (
                    <Tr key={key}>
                      <Th scope="row" className="align-middle text-center">
                        <Kbd>{index + 1}</Kbd>
                      </Th>
                      <Td className="align-middle">
                        {props.orders[key].firstName
                          ? props.orders[key].firstName
                          : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key].lastName
                          ? props.orders[key].lastName
                          : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key].city ? props.orders[key].city : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key] && props.orders[key].bin
                          ? props.orders[key].bin
                          : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key] && props.orders[key].deposit
                          ? `$${props.orders[key].deposit}`
                          : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key].deliveryDate
                          ? new Date(
                              props.orders[key].deliveryDate.toDate()
                            ).toDateString()
                          : "-"}
                      </Td>
                      {/* <Td className="align-middle">
                        <Badge>
                          {props.orders[key].charge &&
                          props.orders[key].charge.status
                            ? props.orders[key].charge.status
                            : "-"}
                        </Badge>
                      </Td> */}
                      <Td className="align-middle">
                        {props.orders[key].createdAt
                          ? new Date(
                              props.orders[key].createdAt.toDate()
                            ).toDateString()
                          : "-"}
                      </Td>
                      <Td className="align-middle">
                        {props.orders[key] && props.orders[key].orderStatus ? (
                          <Badge
                            color={
                              props.orders[key].orderStatus === "new"
                                ? "info"
                                : "success"
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            {props.orders[key].orderStatus}
                          </Badge>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td className="align-middle">
                        <Button
                          outline
                          color="primary"
                          style={{ margin: "0 4px" }}
                          onClick={e =>
                            setState(previewsState => {
                              return {
                                ...previewsState,
                                modalActive: true,
                                order: props.orders[key],
                                orderId: key
                              };
                            })
                          }
                        >
                          More Details
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Col>
          <Col md={12}>
            <Pagination className="d-flex justify-content-center">
              <PaginationItem>
                <PaginationLink previous />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink next />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={state.modalActive}
        size="lg"
        toggle={() =>
          setState(previewsState => {
            return { ...previewsState, modalActive: false };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return { ...previewsState, modalActive: false };
            })
          }
        >
          Order Details
        </ModalHeader>
        <ModalBody>
          {state.order && (
            <Row>
              <Col md="6">
                <OrderDetails>
                  <ul className="order-confirmation">
                    <li>
                      Left at step:
                      <span>{state.order.index ? state.order.index : "-"}</span>
                    </li>
                    <li>
                      Bin type:
                      <span>
                        {state.order.binType
                          ? state.order.binType === "Normal"
                            ? "Junk/Mixed Waste"
                            : "Concrete / Soil"
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Bin size:
                      <span>
                        {state.order.binSize ? state.order.binSize : "-"} Yard
                      </span>
                    </li>
                    <li>
                      Delivery location:
                      <span>{state.order.city ? state.order.city : "-"}</span>
                    </li>
                    <li>
                      Bin purpose:
                      <span>
                        {state.order.property ? state.order.property : "-"}
                      </span>
                    </li>
                    <li>
                      Bin placement:
                      <span>
                        {state.order.drivewaySide
                          ? state.order.drivewaySide
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Driveway Type:
                      <span>
                        {state.order.driveway ? state.order.driveway : "-"}
                      </span>
                    </li>
                    <li>
                      Delivery date:
                      <span>
                        {state.order.deliveryDate
                          ? new Date(
                              state.order.deliveryDate.toDate()
                            ).toDateString()
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Delivery time:
                      <span>
                        {state.order.timeOfDay ? state.order.timeOfDay : "-"}
                      </span>
                    </li>
                    <li>
                      Removal date:
                      <span>
                        {state.order.removalDate
                          ? new Date(
                              state.order.removalDate.toDate()
                            ).toDateString()
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Rate:
                      <span>
                        ${state.order.rate ? state.order.rate : "-"} bin rental
                      </span>
                    </li>
                    <li>
                      <span>
                        ${state.order.disposal ? state.order.disposal : "-"}{" "}
                        Disposal
                      </span>
                    </li>
                    <li>
                      <span>
                        ${state.order.fuel ? state.order.fuel : "-"} Fuel
                      </span>
                    </li>
                    <li>
                      <span>
                        ${state.order.deposit ? state.order.deposit : "-"}{" "}
                        Deposit
                      </span>
                    </li>
                    <li>
                      Additional notes:
                      <span>
                        {state.order.additionalNotes
                          ? state.order.additionalNotes
                          : "-"}
                      </span>
                    </li>
                  </ul>
                </OrderDetails>
              </Col>
              <Col md="6">
                <OrderDetails>
                  <ul className="order-confirmation">
                    <li>
                      First Name:
                      <span>
                        {state.order.firstName ? state.order.firstName : "-"}
                      </span>
                    </li>
                    <li>
                      Last Name:
                      <span>
                        {state.order.lastName ? state.order.lastName : "-"}
                      </span>
                    </li>
                    <li>
                      Street Address:
                      <span>
                        {state.order.streetAddress
                          ? state.order.streetAddress
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Apartment/Suite/Unit etc:
                      <span>
                        {state.order.apartment ? state.order.apartment : "-"}
                      </span>
                    </li>
                    <li>
                      Province:
                      <span>
                        {state.order.province ? state.order.province : "-"}
                      </span>
                    </li>
                    <li>
                      Postal Code:
                      <span>
                        {state.order.postalCode ? state.order.postalCode : "-"}
                      </span>
                    </li>
                    <li>
                      Phone Number:
                      <span>
                        {state.order.phoneNumber
                          ? state.order.phoneNumber
                          : "-"}
                      </span>
                    </li>
                    <li>
                      Email:
                      <span style={{ width: "auto" }}>
                        {state.order.emailAddress
                          ? state.order.emailAddress
                          : "-"}
                      </span>
                    </li>
                  </ul>
                </OrderDetails>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="primary"
            onClick={() =>
              setState(previewsState => {
                return { ...previewsState, modalActive: false };
              })
            }
          >
            Close
          </Button>
          <Button
            color="success"
            onClick={() => {
              setState(previewsState => {
                props.firestore.update(
                  `incompleteOrders/${previewsState.orderId}`,
                  {
                    ...previewsState.order,
                    orderStatus: "completed"
                  }
                );
                return { ...previewsState, modalActive: false };
              });
            }}
          >
            Mark Order Completed
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default compose(
  firestoreConnect([
    { collection: "incompleteOrders", orderBy: ["createdAt", "desc"] }
  ]),
  withFirestore,
  connect(state => ({
    orders: state.firestore.data.incompleteOrders
  }))
)(Orders);
