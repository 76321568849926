import React from "react";
import { Route, Switch } from "react-router-dom";
import BootstrapProvider from "@bootstrap-styled/provider";
import Auth from "./auth";
import Protected from "./protected";

const Admin = () => {
  return (
    <BootstrapProvider>
      <Switch>
        <Route exact path="/admin/auth" component={Auth} />
        <Route path="/admin" component={Protected} />
      </Switch>
      <footer
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          textAlign: "center",
          borderTop: "1px solid #e5e5e5",
          padding: "15px 0",
          backgroundColor: "#fff"
        }}
      >
        &copy; 2019 Hersey Bins | All rights reserved
      </footer>
    </BootstrapProvider>
  );
};

export default Admin;
