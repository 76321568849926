import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import {
  Container,
  Col,
  Row,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Kbd,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  H5
} from "@bootstrap-styled/v4";
import { Trash2 } from "styled-icons/feather/Trash2";

const Trash = styled(Trash2)`
  color: inherit;
`;

const FileUpload = styled.div`
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
`;

const handleClose = () => {};

const Cities = props => {
  const [state, setState] = useState({
    city: "",
    deleteModalActive: false
  });

  useEffect(() => {});

  const submitCity = () => {
    setState({ city: "" });
    props.firestore.add("cities", { name: state.city });
  };

  return (
    <Fragment>
      <Container>
        <Row className="pt-4 pb-4">
          <Col
            md={6}
            className="pb-3 d-flex align-items-center"
            style={{ height: "54px" }}
          >
            <H5 color="gray-dark" className="mb-0">
              All Cities
            </H5>
          </Col>
          <Col md="4">
            <Form>
              <FormGroup>
                <Row>
                  <Col md={12}>
                    <Input
                      type="text"
                      placeholder="City Name"
                      value={state.city}
                      onChange={e => setState({ city: e.target.value })}
                      onKeyPress={e => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          submitCity();
                        }
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Col>
          <Col md={2} className="pb-3 d-flex justify-content-end">
            <Button
              outline
              color="success"
              style={{ width: "100%" }}
              onClick={e => submitCity()}
              disabled={state.city === ""}
            >
              Add new city
            </Button>
          </Col>
          <Col md={12}>
            <Table bordered>
              <Thead>
                <Tr>
                  <Th />
                  <Th>City</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {!!props.cities &&
                  Object.keys(props.cities).map((key, index) =>
                    props.cities[key] ? (
                      <Tr key={key}>
                        <Th
                          scope="row"
                          className="align-middle text-center"
                          style={{ width: "80px" }}
                        >
                          <Kbd>{index + 1}</Kbd>
                        </Th>
                        <Td className="align-middle">
                          {props.cities[key].name}
                        </Td>
                        <Td className="text-center" style={{ width: "90px" }}>
                          <Button
                            outline
                            color="danger"
                            style={{ margin: "0 4px" }}
                            onClick={e =>
                              setState(previewsState => {
                                return {
                                  ...previewsState,
                                  deleteModalActive: true,
                                  keyForDeleteModal: key,
                                  nameForDeleteModal: props.cities[key].name
                                };
                              })
                            }
                          >
                            <Trash size={20} />
                          </Button>
                        </Td>
                      </Tr>
                    ) : null
                  )}
              </Tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={false} size="lg" toggle={() => handleClose()}>
        <ModalHeader toggle={() => handleClose()}>Add New Bin</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Image</Label>
                  <InputGroup>
                    <FileUpload>
                      <Input type="file" className="custom-file-input" />
                      <label className="custom-file-label">Choose Image</label>
                    </FileUpload>
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Type</Label>
                  <Input type="text" placeholder="Type" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Size</Label>
                  <Input type="number" placeholder="Size" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={12}>
                  <Label>Description</Label>
                  <Input
                    style={{ height: "120px" }}
                    type="textarea"
                    placeholder="Description"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button color="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleClose()}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={state.deleteModalActive}
        size="md"
        toggle={() =>
          setState(previewsState => {
            return {
              ...previewsState,
              deleteModalActive: false
            };
          })
        }
      >
        <ModalHeader
          toggle={() =>
            setState(previewsState => {
              return {
                ...previewsState,
                deleteModalActive: false
              };
            })
          }
        >
          Delete City {state.nameForDeleteModal}?
        </ModalHeader>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            onClick={() =>
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              })
            }
          >
            No
          </Button>
          <Button
            color="primary"
            onClick={() => {
              state.keyForDeleteModal &&
                props.firestore.delete(`cities/${state.keyForDeleteModal}`);
              setState(previewsState => {
                return {
                  ...previewsState,
                  deleteModalActive: false
                };
              });
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default compose(
  firestoreConnect(["cities"]),
  withFirestore,
  connect(state => ({
    cities: state.firestore.data.cities
  }))
)(Cities);
